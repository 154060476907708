import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import "./CategoryCollection.css";
import { FaImage, FaChevronLeft, FaChevronRight } from "react-icons/fa";

// Default collection items
const defaultCollectionItems = [
  { label: "Dog" },
  { label: "Cat" },
  { label: "Wet Food" },
  { label: "Dry Food" },
  { label: "Treats" },
  { label: "Toys" },
  { label: "Apparel" },
  { label: "Leashes" },
  { label: "Litter" },
  { label: "Grooming" },
  { label: "Enrichment" },
  { label: "Travel" },
];

const CategoryCollection = ({
  title = "EXPLORE OUR COLLECTIONS",
  collectionItems = defaultCollectionItems, // Use defaultCollectionItems if not provided
}) => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);

  const scrollDistance = 300;

  const checkButtonVisibility = () => {
    if (containerRef.current) {
      const maxScrollLeft =
        containerRef.current.scrollWidth - containerRef.current.clientWidth;
      const currentScrollLeft = containerRef.current.scrollLeft;

      setShowPrevButton(currentScrollLeft > 0);
      setShowNextButton(currentScrollLeft < maxScrollLeft);
    }
  };

  const handleNextClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: scrollDistance, behavior: "smooth" });
      setTimeout(checkButtonVisibility, 300);
    }
  };

  const handlePrevClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -scrollDistance, behavior: "smooth" });
      setTimeout(checkButtonVisibility, 300);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", checkButtonVisibility);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", checkButtonVisibility);
      }
    };
  }, []);

  const handleCollectionClick = (label) => {
    // Navigate to the MasterCategoryPage for the clicked collection
    navigate(`/category/${label}`);
  };

  return (
    <div className="collections-container">
      <h2 className="collections-title">{title}</h2>
      <div className="scroll-wrapper">
        {showPrevButton && (
          <button className="scroll-button prev-button" onClick={handlePrevClick}>
            <FaChevronLeft />
          </button>
        )}
        <div className="collections-items" ref={containerRef}>
          {collectionItems.map((item, index) => (
            <div
              className="collection-item"
              key={index}
              onClick={() => handleCollectionClick(item.label)} // Navigate on click
            >
              <div className="collection-icon">
                {item.icon || <FaImage className="placeholder-icon" />} {/* Support custom icons */}
              </div>
              <p className="collection-label">{item.label}</p>
            </div>
          ))}
        </div>
        {showNextButton && (
          <button className="scroll-button next-button" onClick={handleNextClick}>
            <FaChevronRight />
          </button>
        )}
      </div>
    </div>
  );
};

export default CategoryCollection;
