import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import MenuBar from './components/MenuBar';
import TopBar from './components/TopBar';
import SlideshowContainer from './components/SlideshowContainer';
import ActionBlock from './components/ActionBlock';
import CategoryCollection from './components/CategoryCollection';
import SmallHorizontalAd from './components/SmallHorizontalAd';
import DogProductCollection from './components/DogProductCollection';
import DogCollections from './components/DogCollections';
import FullHorizontalAd from './components/FullHorizontalAd';
import WhyShopWithUs from './components/WhyShopWithUs';
import StaffsPick from './components/StaffsPick';
import FullTwoImagesHorizontalAd from './components/FullTwoImagesHorizontalAd';
import BrandCollection from './components/BrandCollection';
import CatProductCollection from './components/CatProductCollection';
import CatCollections from './components/CatCollections';
import FeaturedBlogs from './components/FeaturedBlogs';
import BottomBar from './components/BottomBar';
import Footer from './components/Footer';
import CookieBar from './components/CookieBar';
import './App.css';
import Carna4FeatureSection from "./components/Carna4FeatureSection";
import BoxieCatFeatureSection from "./components/BoxieCatFeatureSection";
import PetServicesSection from "./components/PetServicesSection";
import HomePageAboutUsSection from "./components/HomePageAboutUsSection";
import NZNaturalDoubleFeatureAd from "./components/NZNaturalDoubleFeatureAd";
import NewsletterSubscribe from "./components/NewsletterSubscribe";
import SkoutsHonorAd from "./components/SkoutsHonorAd";
import OutwardHoundProductFeature from "./components/OutwardHoundProductFeature";
import NewArrivalsCollection from "./components/NewArrivalsCollection";
import CategoryPage from "./pages/CategoryPage";
import ScrollHandler from "./components/ScrollHandler";
import MasterCategoryPage from "./pages/MasterCategoryPage";

const App = () => {
  const [isMenuFullyVisible, setIsMenuFullyVisible] = useState(true);
  const [contentHeight, setContentHeight] = useState('auto');

  // Update content height dynamically
  useEffect(() => {
    const updateContentHeight = () => {
      const topBarHeight = document.querySelector('.top-bar')?.offsetHeight || 0;
      const headerHeight = document.querySelector('.header')?.offsetHeight || 0;
      const menuBarHeight = document.querySelector('.menu-bar')?.offsetHeight || 0;

      const totalHeight = topBarHeight + headerHeight + menuBarHeight;
      const viewportHeight = window.innerHeight;
      const calculatedHeight = viewportHeight - totalHeight;

      setContentHeight(`${calculatedHeight}px`);
    };

    updateContentHeight();
    window.addEventListener('resize', updateContentHeight);
    return () => window.removeEventListener('resize', updateContentHeight);
  }, []);

  // Monitor menu bar visibility
  useEffect(() => {
    const menuBar = document.querySelector('.menu-bar');

    if (menuBar && 'IntersectionObserver' in window) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => setIsMenuFullyVisible(entry.isIntersecting));
        },
        { threshold: 1.0 }
      );

      observer.observe(menuBar);
      return () => observer.disconnect();
    } else {
      setIsMenuFullyVisible(true); // Default to visible for unsupported cases
    }
  }, []);

  return (
    <Router>
      <ScrollHandler />
      <div className="app-container">
        {/* Top Bar */}
        <TopBar message="Free shipping on orders over $75!" speed={20} className="top-bar" />
        
        {/* Header */}
        <Header isMenuVisible={isMenuFullyVisible} className="header" />
        
        {/* Menu Bar */}
        <MenuBar className="menu-bar" />

        {/* Routes */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div className="content-container" style={{ height: contentHeight }}>
                  <SlideshowContainer />
                </div>
                <div className="scrollable-content">
                  <ActionBlock />
                  <CategoryCollection />
                  <NZNaturalDoubleFeatureAd />
                  <DogProductCollection />
                  <Carna4FeatureSection />
                  <DogCollections />
                  <HomePageAboutUsSection />
                  <StaffsPick />
                  <FullTwoImagesHorizontalAd />
                  <CatProductCollection />
                  <BoxieCatFeatureSection />
                  <CatCollections />
                  <SkoutsHonorAd />
                  <NewArrivalsCollection />
                  <OutwardHoundProductFeature />
                  <BrandCollection />
                  <SmallHorizontalAd />
                  <PetServicesSection />
                  <FullHorizontalAd />
                  <FeaturedBlogs />
                  <NewsletterSubscribe />
                </div>
              </>
            }
          />
          <Route
            path="/category/:parentCategory/:category"
            element={<CategoryPage />}
          />
          <Route
            path="/category/:category"
            element={<MasterCategoryPage />}
          />
        </Routes>
        <BottomBar />
        <Footer />
        <CookieBar />
      </div>
    </Router>
  );
};

export default App;
