import React, { useState } from "react";
import {FaChevronDown } from 'react-icons/fa';
import "./ResultCountAndSort.css";

const ResultCountAndSort = ({
  pageNumber = 1,
  resultsPerPage = 20,
  totalResults = 4000, // Placeholder for the number of results
  sortOptions = ["Newest", "Price: Low to High", "Price: High to Low", "Best Sellers"], // Sorting options
  onSortChange, // Callback for when sort option changes
}) => {
  const [selectedSort, setSelectedSort] = useState(sortOptions[0]); // Default to the first option
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Toggle dropdown visibility

  // Calculate the result range
  const startResult = (pageNumber - 1) * resultsPerPage + 1;
  const endResult = Math.min(pageNumber * resultsPerPage, totalResults);

  const handleSortChange = (option) => {
    setSelectedSort(option); // Update the selected sort
    setIsDropdownOpen(false); // Close the dropdown
    if (onSortChange) {
      onSortChange(option); // Trigger the callback
    }
  };

  return (
    <div className="result-sort-container">
      {/* Result Count */}
      <div className="result-count">
        {startResult} - {endResult} of {totalResults} Results
      </div>

      {/* Sort By */}
      <div
        className="sort-by-container"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <div className="sort-by-label">Sort by</div>
        <div className="sort-by-selected">
          {selectedSort} <span className="dropdown-arrow"><FaChevronDown className="result-count-and-sort-down-icon" /></span>
        </div>
        {isDropdownOpen && (
          <div className="sort-by-dropdown">
            {sortOptions.map((option, index) => (
              <div
                key={index}
                className="sort-by-option"
                onClick={() => handleSortChange(option)}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ResultCountAndSort;
