import React from "react";
import "./CategoryPageBottomAd.css";

const CategoryPageBottomAd = ({
  message,
  subMessage,
  buttonText = "READ MORE",
  onClick,
}) => {
  return (
    <div className="category-page-bottom-ad-wrapper">
      {/* Image Section */}
      <div className="category-page-bottom-ad-image-container">
        <img
          src="https://cdn.thelittlepetshoppe.com/feeding-dog-with-hand.jpg"
          alt="Dog being fed"
          className="category-page-bottom-ad-image"
        />
      </div>

      {/* Content Section */}
      <div className="category-page-bottom-ad-content">
        <div className="category-page-bottom-ad-text">
          <p className="category-page-bottom-ad-main-text">{message}</p>
          <p className="category-page-bottom-ad-sub-text">{subMessage}</p>
        </div>
        <button className="category-page-bottom-ad-button" onClick={onClick}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default CategoryPageBottomAd;
