import React, { useState } from 'react';
import './SlideshowContainer.css'; // Import the CSS for styling
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'; // Import icons
import SlideshowPaginationBar from './SlideshowPaginationBar'; // Import the new PaginationBar component

const SlideshowContainer = () => {
  const [isPaused, setIsPaused] = useState(false);

  // Toggle function for pause/play button
  const togglePlayPause = () => {
    setIsPaused((prev) => !prev);
  };

  // Placeholder functions for navigation
  const handlePrevious = () => {
    console.log("Previous slide");
  };

  const handleNext = () => {
    console.log("Next slide");
  };

  return (
    <div className="slideshow-wrapper">
      <div className="slideshow-container">
        {/* Previous Button */}
        <button className="nav-button prev-button" onClick={handlePrevious}>
          <FaChevronLeft />
        </button>

        <div className="slideshow-border">
          <div className="slide-content">
            {/* Add your slides or content here */}
          </div>
        </div>

        {/* Next Button */}
        <button className="nav-button next-button" onClick={handleNext}>
          <FaChevronRight />
        </button>
      </div>

      {/* Replace bottom-bar with PaginationBar */}
      <SlideshowPaginationBar isPaused={isPaused} togglePlayPause={togglePlayPause} dotCount={7} />
    </div>
  );
};

export default SlideshowContainer;
