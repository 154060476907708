import React from 'react';
import PetCollections from './PetCollections';

const catCollectionItems = [
  { id: 1, name: 'Catnip Toys', image: '' },
  { id: 2, name: 'Scratchers', image: '' },
  { id: 3, name: 'Litter', image: '' },
  { id: 4, name: 'Beds', image: '' },
  { id: 5, name: 'Dry Food', image: '' },
  { id: 6, name: 'Wet Food', image: '' },
  { id: 7, name: 'Treats', image: '' },
  { id: 8, name: 'Collars', image: '' },
  { id: 9, name: 'Grooming', image: '' },
  { id: 10, name: 'Travel Carriers', image: '' },
];

const CatCollections = () => (
  <PetCollections
      title="Cat Collections"
      parentCategory="Cat" // Pass parentCategory
      collectionItems={catCollectionItems}
  />
);

export default CatCollections;
