import React, { useState } from 'react';
import './CategoryPageCategorySection.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const categories = [
  { id: 1, name: 'Dog' },
  { id: 2, name: 'Cat' },
  { id: 3, name: 'Treats' },
  { id: 4, name: 'Toys' },
  { id: 5, name: 'Dry Food' },
  { id: 6, name: 'Wet Food' },
];

const CategoryPageCategorySection = () => {
  const [expandedCategory, setExpandedCategory] = useState(null);

  const handleToggle = (id) => {
    setExpandedCategory((prev) => (prev === id ? null : id));
  };

  return (
    <div className="category-page-category-section">
      <h2 className="category-page-category-title">Category</h2>
      <ul className="category-page-category-list">
        {categories.map((category) => (
          <li key={category.id} className="category-page-category-item">
            <div
              className="category-page-category-header"
              onClick={() => handleToggle(category.id)}
            >
              <span className="category-page-category-name">{category.name}</span>
              {expandedCategory === category.id ? (
                <FaChevronUp className="category-page-icon" />
              ) : (
                <FaChevronDown className="category-page-icon" />
              )}
            </div>
            {expandedCategory === category.id && (
              <div className="category-page-category-dropdown">
                <ul>
                  <li>Subcategory 1</li>
                  <li>Subcategory 2</li>
                  <li>Subcategory 3</li>
                </ul>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryPageCategorySection;
