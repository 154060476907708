import React from "react";
import CategoryPageCategorySelector from "./CategoryPageCategorySelector";

const PlaceholderCategorySelector = () => {
  const categories = [
    "Lorem ipsum 1",
    "Lorem ipsum 2",
    "Lorem ipsum 3",
    "Lorem ipsum 4",
    "Lorem ipsum 5",
    "Lorem ipsum 6",
    "Lorem ipsum 7",
    "Lorem ipsum 8",
    "Lorem ipsum 9",
    "Lorem ipsum 10",
    "Lorem ipsum 11",
    "Lorem ipsum 12",
    "Lorem ipsum 13",
    "Lorem ipsum 14",
    "Lorem ipsum 15",
    "Lorem ipsum 16",
    "Lorem ipsum 17",
    "Lorem ipsum 18",
    "Lorem ipsum 19"
  ];

  return (
    <div>
      <CategoryPageCategorySelector
        title="Lorem Ipsum"
        categories={categories}
        showSearch={false} // Set to false if you want to hide the search bar
      />
    </div>
  );
};

export default PlaceholderCategorySelector;
