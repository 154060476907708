import React from "react";
import AnimatedLetterSequence from "./AnimatedLetterSequence";
import WorldMapDots from "./WorldMapDots";
import "./NewsletterSubscribe.css";

const NewsletterSubscribe = () => {
  return (
    <div className="newsletter-container">
      {/* Background World Map */}
      <WorldMapDots className="world-map-background" />

      {/* Left Section: Animated Letter */}
      <div className="newsletter-left">
        <AnimatedLetterSequence />
      </div>

      {/* Right Section: Subscription Form */}
      <div className="newsletter-right">
        <h2 className="newsletter-title">SUBSCRIBE TO OUR NEWSLETTER</h2>
        <p className="newsletter-description">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </p>
        <div className="newsletter-form">
          <input
            type="text"
            placeholder="Enter your email"
            className="newsletter-input"
          />
          <input
            type="text"
            placeholder="Your Name"
            className="newsletter-input"
          />
          <button className="subscribe-button">Subscribe</button>
        </div>

        <div className="newsletter-agreement">
          <input type="checkbox" id="agree" />
          <label htmlFor="agree">I agree to receive marketing material</label>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSubscribe;
