import React from 'react';
import './SlideshowPaginationBar.css'; // Import CSS for PaginationBar
import { FaPause, FaPlay } from 'react-icons/fa';

const SlideshowPaginationBar = ({ isPaused, togglePlayPause, dotCount = 5 }) => {
  return (
    <div className="slideshow-pagination-bar-wrapper">
      <div className="slideshow-pagination-bar-container">
        {/* Pause/Play Button */}
        <div className="slideshow-pagination-bar-pause-play-button" onClick={togglePlayPause}>
          {isPaused ? <FaPlay /> : <FaPause />}
        </div>

        {/* Pagination Dots */}
        {Array.from({ length: dotCount }).map((_, index) => (
          <div key={index} className={`slideshow-pagination-bar-dot ${index === 0 ? 'active' : ''}`}></div>
        ))}
      </div>
    </div>
  );
};

export default SlideshowPaginationBar;
