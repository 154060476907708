import React from "react";
import { useParams } from "react-router-dom";
import "./MasterCategoryPageHeaderCategories.css";
import CategoryCollection from "./CategoryCollection";
import DogCollections from "./DogCollections";

const MasterCategoryPageHeaderCategories = () => {
  const { category } = useParams(); // Retrieve the category parameter from the URL

  return (
    <div className="master-category-header">
      {/* Breadcrumb
      <div className="master-category-breadcrumb">
        <span>{category}</span>
      </div>
      <CategoryCollection
          title={`${category} Collections`}
          collectionItems={[
              { label: 'Dry Food' },
              { label: 'Wet Food' },
              { label: 'Treats' },
              { label: 'Toys' },
              { label: 'Harnesses' },
              { label: 'Collars' },
              { label: 'Leashes' },
              { label: 'Grooming' },
              { label: 'Travel' },
              { label: 'Training' },
          ]}
      />*/}
      <DogCollections />

    </div>
  );
};

export default MasterCategoryPageHeaderCategories;
