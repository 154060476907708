import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './PetCollections.css';
import { FaChevronLeft, FaChevronRight, FaImage } from 'react-icons/fa';

const PetCollections = ({ title, parentCategory, collectionItems }) => {
  const containerRef = useRef(null);
  const navigate = useNavigate();

  const handleNextClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  const handlePrevClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  return (
    <div className="pet-collections-wrapper">
      <h2 className="collection-title">{title}</h2>
      <div className="scroll-container">
        <button className="scroll-button left" onClick={handlePrevClick}>
          <FaChevronLeft />
        </button>
        <div className="pet-collections" ref={containerRef}>
          {collectionItems.map((item) => (
            <div
              className="pet-collection-item"
              key={item.id}
              onClick={() => navigate(`/category/${parentCategory}/${item.name}`)} // Navigate with both parentCategory and item name
            >
              <div className="pet-collection-image-container">
                {item.image ? (
                  <img
                    src={item.image}
                    alt={item.name}
                    className="pet-collection-image"
                  />
                ) : (
                  <FaImage className="placeholder-icon" />
                )}
              </div>
              <p className="pet-collection-name">{item.name}</p>
            </div>
          ))}
        </div>
        <button className="scroll-button right" onClick={handleNextClick}>
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default PetCollections;
