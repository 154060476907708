import React from 'react';
import ProductCollection from './ProductCollection';

const dogProducts = [
    {
      id: 1,
      name: 'Green Juju',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 2,
      name: 'The Healer',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 3,
      name: 'Lotus',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 4,
      name: 'Lotus',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 5,
      name: 'Lotus',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 6,
      name: 'Lotus',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 7,
      name: 'Lotus',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 8,
      name: 'Lotus',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    },
    {
      id: 9,
      name: 'Lotus',
      image: '',
      brand: 'Brand Name',
      price: '19.99',
    }
];

const CategoryPageRecommendedProducts = () => (
  <ProductCollection title="You May Also Like" products={dogProducts} />
);

export default CategoryPageRecommendedProducts;
