import * as React from "react";
const WorldMapDots = ({className}) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1164.84 800"
    className={className}
  >
    <defs>
      <style>
        {
          "\n      .world-map-dots-1, .world-map-dots-2 {\n        fill: #c6c6c6;\n      }\n\n      .world-map-dots-2 {\n        fill-rule: evenodd;\n      }\n    "
        }
      </style>
    </defs>
    <g id="Objects">
      <path
        className="world-map-dots-2"
        d="M587.75,376.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,376.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,376.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,376.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,384c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,376.08c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,384c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,391.92c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,399.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,391.92c0,1.52-1.24,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,399.84c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,407.34c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,415.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,407.34c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,415.26c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,423.18c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,431.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,423.18c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,431.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,423.18c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,431.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,423.18c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,431.1c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,423.18c0,1.52-1.24,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,431.1c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,376.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,376.08c0,1.52-1.22,2.74-2.73,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,384c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,376.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.14,376.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.14,384c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.92,376.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.92,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,391.92c0,1.52-1.22,2.75-2.73,2.75s-2.75-1.23-2.75-2.75,1.23-2.74,2.75-2.74c1.51,0,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,399.84c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.14,391.92c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.14,399.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.92,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.92,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,407.34c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.73,1.24,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,415.26c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.75,2.75-2.75c1.51,0,2.73,1.24,2.73,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <circle className="world-map-dots-1" cx={569.4} cy={407.34} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M572.14,415.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.92,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.92,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M524.87,376.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M524.87,384c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M532.65,376.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M532.65,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.5,376.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.5,384c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M524.87,391.92c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M524.87,399.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M532.65,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M532.65,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.5,391.92c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.5,399.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={522.13} cy={407.34} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M524.87,415.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <circle className="world-map-dots-1" cx={529.91} cy={407.34} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M532.65,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.5,407.34c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.5,415.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75c1.52,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.7,376.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.7,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.49,376.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.49,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M501.33,376.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M501.33,384c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,376.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,376.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.7,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.7,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M478.33,376.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M478.33,384c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M469.35,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M478.33,391.92c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M478.33,399.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.49,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.49,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M501.33,391.92c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M501.33,399.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.7,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.7,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.49,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.49,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M501.33,407.34c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M501.33,415.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75c1.52,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75c1.52,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M524.87,423.17c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.49,423.17c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M501.33,423.17c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,423.17c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,423.17c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,423.18c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,423.18c0,1.52-1.22,2.75-2.73,2.75s-2.75-1.23-2.75-2.75,1.23-2.73,2.75-2.73c1.51,0,2.73,1.22,2.73,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,423.18c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,431.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.14,423.18c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.14,431.1c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.92,423.18c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.92,431.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,438.96c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,446.89c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,438.96c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,446.89c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,438.96c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,446.89c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,438.96c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,446.89c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,438.96c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,446.89c0,1.51-1.24,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.22-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,454.8c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,462.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,454.8c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,462.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,454.8c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,462.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,454.8c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,462.72c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.25,446.89c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.1,438.96c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.1,446.89c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M580.03,438.96c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M580.03,446.89c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.25,454.8c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.52,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.1,454.8c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.52,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.1,462.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M580.03,454.8c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M580.03,462.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,454.8c0,1.52-1.24,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,462.72c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,376.08c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,384c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,376.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,384c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,376.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,376.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,376.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,391.92c0,1.52-1.24,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.5,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,399.84c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,391.92c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,399.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,407.34c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,415.26c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75c1.5,0,2.74,1.24,2.74,2.75Z"
      />
      <circle className="world-map-dots-1" cx={631.96} cy={407.34} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M634.7,415.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75c1.52,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,423.18c0,1.52-1.24,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.5,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,431.1c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,423.18c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.52,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,431.1c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,423.18c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,431.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,423.18c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,431.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,423.18c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,431.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,438.96c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,446.89c0,1.51-1.24,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,438.96c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,446.89c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,438.96c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,446.89c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,438.96c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,446.89c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,438.96c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,446.89c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.22-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,454.8c0,1.52-1.24,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.5,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,462.72c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,454.8c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.52,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,462.72c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,454.8c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,462.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,454.8c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,462.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,454.8c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,462.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,470.58c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,478.51c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.5-.01,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={631.96} cy={470.58} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M634.7,478.51c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,470.58c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,478.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,470.58c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,478.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,470.58c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,478.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,486.43c0,1.51-1.24,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,494.34c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,486.43c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <circle className="world-map-dots-1" cx={631.96} cy={494.34} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M642.56,486.43c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,494.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,486.43c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,494.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,486.43c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,494.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,501.85c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,509.76c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,501.85c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={631.96} cy={509.76} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M642.56,501.85c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,509.76c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,501.85c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,509.76c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,501.85c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,509.76c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,517.69c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,525.6c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,517.69c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,525.6c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,517.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,525.6c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,517.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,525.6c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,517.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,376.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,384c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,360.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,368.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,360.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,368.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,360.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,368.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,360.63c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,368.55c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,360.63c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,368.55c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,360.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,368.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,360.63c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,368.55c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,360.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,368.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={569.4} cy={360.63} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M572.14,368.55c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.92,360.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.92,368.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={522.13} cy={360.63} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M524.87,368.55c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={529.91} cy={360.63} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M532.65,368.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.5,360.63c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.5,368.55c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.7,360.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.7,368.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.49,360.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.49,368.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M501.33,360.63c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M501.33,368.55c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,360.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,368.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,360.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,368.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={475.59} cy={360.63} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M478.33,368.55c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,360.63c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,368.55c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={631.96} cy={360.63} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M634.7,368.55c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,360.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,368.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,360.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,368.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,360.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.26,368.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,344.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,352.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,344.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,352.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,344.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,352.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,344.46c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,352.38c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,344.46c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,352.38c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,344.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,352.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,344.46c0,1.52-1.22,2.74-2.73,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,352.38c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,344.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,352.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={569.4} cy={344.46} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M572.14,352.38c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.92,344.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.92,352.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={522.13} cy={344.46} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M524.87,352.38c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={529.91} cy={344.46} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M532.65,352.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.5,344.46c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.5,352.38c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.7,344.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.7,352.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.49,344.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.49,352.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M501.33,344.46c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M501.33,352.38c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,344.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,352.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,344.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,352.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M478.33,352.38c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,344.46c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,352.38c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={631.96} cy={344.46} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M634.7,352.38c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,344.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,352.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,344.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,337c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,337c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,337c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,337c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,337c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,337c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,337c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74c1.51,0,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,337c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.14,337c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.92,337c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M524.87,337c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M532.65,337c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.5,337c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.7,337c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.49,337c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M501.33,337c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,337c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,337c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={451.61} cy={180.17} r={2.74} />
      <circle className="world-map-dots-1" cx={459.39} cy={180.17} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M469.99,180.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M430.63,180.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={435.68} cy={180.17} r={2.74} />
      <circle className="world-map-dots-1" cx={443.53} cy={180.17} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M407.1,180.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M415.02,180.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={420.06} cy={180.17} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M439.31,188.27c0,1.52-1.23,2.74-2.75,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M447.09,188.27c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M415.59,188.27c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M423.38,188.27c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M431.23,188.27c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={397.24} cy={188.27} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M407.76,188.27c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M415.59,196.42c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M423.38,196.42c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={397.24} cy={196.42} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M407.76,196.42c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M415.59,203.86c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={397.24} cy={203.86} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M407.76,203.86c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M407.76,211.65c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M454.35,164.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M454.35,172.64c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M462.13,164.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M462.13,172.64c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M469.99,164.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M469.99,172.64c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M477.9,164.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M477.9,172.64c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.69,164.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M430.63,164.72c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M430.63,172.64c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M438.42,164.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M438.42,172.64c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M446.27,164.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M446.27,172.64c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M407.1,164.72c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M407.1,172.64c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M415.02,164.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M415.02,172.64c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M422.81,164.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M422.81,172.64c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.52,148.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.52,156.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M454.35,148.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={451.61} cy={156.47} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M462.13,148.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={459.39} cy={156.47} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M469.99,148.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M469.99,156.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M477.9,148.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M477.9,156.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.69,148.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.69,156.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M430.63,148.55c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M430.63,156.47c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M438.42,148.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={435.68} cy={156.47} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M446.27,148.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={443.53} cy={156.47} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M391.47,148.55c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M376.3,148.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M391.47,156.47c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M399.25,148.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M399.25,156.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M407.1,148.55c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M407.1,156.47c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M415.02,148.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M415.02,156.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M422.81,148.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={420.06} cy={156.47} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M384.1,156.47c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.52,141.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={451.61} cy={141.1} r={2.74} />
      <circle className="world-map-dots-1" cx={459.39} cy={141.1} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M469.99,141.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M477.9,141.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.69,141.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M391.47,141.1c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M383.67,141.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M399.25,141.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M368.09,148.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.85,148.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.64,148.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M375.87,156.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.21,156.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M368.09,141.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M375.87,141.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,337c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,337c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,337c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,329.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,329.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,329.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,329.1c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,329.1c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,329.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,329.1c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74c1.51,0,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,329.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={569.4} cy={329.1} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M579.92,329.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={522.13} cy={329.1} r={2.74} />
      <circle className="world-map-dots-1" cx={529.91} cy={329.1} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M540.5,329.1c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M501.33,329.1c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,329.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,329.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,321.55c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,321.55c0,1.52-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,321.55c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.14,321.55c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.92,321.55c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M524.87,321.55c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M532.65,321.55c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.5,321.55c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M501.33,321.55c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,321.55c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,321.55c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,313.29c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,313.29c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74c1.51,0,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,313.29c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M524.87,313.29c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M532.65,313.29c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.5,313.29c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.59,305.75c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.37,305.75c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74c1.51-.01,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,305.75c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M532.65,305.75c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={529.91} cy={289.93} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M540.5,305.75c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,313.29c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,313.29c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M524.87,297.48c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,297.48c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,297.48c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M524.87,290.29c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,290.29c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M502.07,290.29c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,290.29c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M532.65,281.67c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.56,281.67c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M532.65,274.12c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.56,274.12c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.04,274.12c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M555.95,274.12c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M532.65,266.58c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.56,266.58c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.04,266.58c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M555.95,266.58c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M532.65,259.03c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M524.75,259.03c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.14,235.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.23,235.31c0,1.51-1.22,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M493.78,196.15c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M485.88,196.15c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M478.7,196.15c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.5,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M532.65,242.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M524.75,242.86c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M508.94,242.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.56,259.03c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.04,259.03c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M555.95,259.03c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M540.56,251.12c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={522.67} cy={227.41} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M532.59,235.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.04,251.12c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M555.95,251.12c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={522.13} cy={282.03} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M509.25,282.03c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,282.03c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M509.25,275.2c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M517.04,275.2c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,329.1c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={631.96} cy={329.1} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M642.56,329.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={646.9} cy={329.1} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M650.47,352.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,289.71c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.31,298.2c0,1.52-1.23,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M563.86,289.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,281.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,289.71c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,281.78c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,281.78c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,297.63c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,305.54c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,320.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,320.97c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,281.78c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,289.71c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,289.71c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,281.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,289.71c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,281.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,289.71c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,297.63c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,297.72c-.06,1.52-1.32,2.7-2.84,2.64-1.51-.05-2.69-1.33-2.63-2.84.05-1.51,1.33-2.69,2.84-2.63,1.51.05,2.68,1.32,2.63,2.83Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,297.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,297.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,266.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,274.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,266.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,274.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,266.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,274.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,266.34c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,274.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,266.34c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,274.26c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,266.34c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,274.26c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,266.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,250.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,258.09c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,250.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,258.09c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,250.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,258.09c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,250.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,258.09c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,250.17c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,258.09c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,250.17c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,258.09c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,250.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,258.09c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,250.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,258.09c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,235.39c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,242.71c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,242.71c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,242.71c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,242.71c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,242.71c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,242.71c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,242.71c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,242.71c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,234.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.54,234.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={569.5} cy={266.34} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M572.24,274.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M580.03,266.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M580.03,282.38c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.24,250.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.24,258.09c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M580.03,250.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M580.03,258.09c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.24,242.71c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M580.03,242.71c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={569.5} cy={234.81} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M564.34,227.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,266.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,250.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,258.09c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,242.71c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.43,242.71c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M548.4,242.71c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,234.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M580.03,234.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,234.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,234.81c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,234.81c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,234.81c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={631.96} cy={234.81} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M642.56,234.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.55,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.33,250.53c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.24-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.16,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.94,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.79,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.71,250.53c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.55,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.33,243.07c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.24-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.16,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={718.2} cy={243.07} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M728.79,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,250.53c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,243.07c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,266.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,266.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,266.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,259.01c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,259.01c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,259.01c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,266.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,266.47c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,259.01c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.5,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.5,259.01c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,259.01c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,282.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,282.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,282.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,290.29c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,274.71c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,274.71c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,274.71c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,274.71c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,235.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.55,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.33,235.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.24-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.16,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.94,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.79,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,227.23c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,227.23c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,227.23c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,227.23c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,227.23c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,227.23c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,227.23c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,219.78c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,219.78c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,219.78c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={631.96} cy={219.78} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M642.56,219.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,211.87c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.75,204.08c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.24,227.23c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M580.03,227.23c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={569.5} cy={219.78} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M580.03,219.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={569.5} cy={211.87} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M564.34,204.33c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M564.22,211.87c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.39,204.33c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M556.27,211.87c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M580.03,211.87c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={569.5} cy={203.86} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M580.03,203.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={569.5} cy={195.95} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M580.03,195.95c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M580.03,188.16c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.39,211.87c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.3,211.87c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.09,211.87c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,211.87c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={631.96} cy={211.87} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M642.56,211.87c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,227.59c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,227.59c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,227.59c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.55,227.59c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.33,227.59c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.24-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.16,227.59c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.94,227.59c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.79,227.59c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.71,227.59c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,220.14c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,220.14c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,220.14c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.55,220.14c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.33,220.14c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.24-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.16,220.14c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={718.2} cy={220.14} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M728.79,220.14c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,212.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,212.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,227.59c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,227.59c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,312.87c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,312.87c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,305.42c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,305.42c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,297.52c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,297.52c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,312.87c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,305.42c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,328.82c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,328.82c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,321.37c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,321.37c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,328.82c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,321.37c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,344.52c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.2,344.52c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,344.52c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,337.06c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,337.06c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,337.06c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.12,337.06c0,1.52-1.23,2.74-2.74,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,360.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,368.59c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,360.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,352.65c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,352.65c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,352.65c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,297.52c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,289.95c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,289.95c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,289.95c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,220.14c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,220.14c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,212.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,328.11c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,320.65c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,312.75c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.24-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,305.18c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,297.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,289.82c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,212.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,212.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.55,212.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.33,212.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.24-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.16,212.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.94,212.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.79,212.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,203.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,203.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,203.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.55,203.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.33,203.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.24-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.16,203.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.94,203.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.79,203.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,195.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,195.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,203.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,203.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,195.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,195.94c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,195.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.55,195.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.33,195.94c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.24-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.16,195.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={718.2} cy={195.94} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M728.79,195.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.99,188.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,188.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M658.48,188.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.35,188.14c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.68,220.14c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.68,212.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.68,203.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.35,203.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.68,195.94c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.88,203.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.66,203.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.88,195.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.66,195.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.37,203.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.15,203.84c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.37,195.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.15,195.94c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.57,203.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.57,195.94c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.88,188.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.66,188.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.37,188.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.15,188.14c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.57,188.14c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.88,180.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.66,180.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M611.37,180.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M619.15,180.47c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.57,180.47c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.58,195.94c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.58,188.14c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.58,180.47c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={585.05} cy={195.94} r={2.74} />
      <circle className="world-map-dots-1" cx={585.05} cy={188.14} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M587.79,180.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.57,157.09c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.58,157.09c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.57,148.6c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.58,148.6c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.79,157.09c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.29,157.09c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.26,188.14c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,188.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,172.2c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.77,164.4c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,164.4c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.55,188.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.33,188.14c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.24-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.55,180.35c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.33,180.35c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.24-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.16,188.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={718.2} cy={188.14} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M728.79,188.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.94,180.35c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.79,180.35c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.79,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.58,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.43,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.35,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.13,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M798.96,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.74,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.59,250.53c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.51,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.79,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.58,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.43,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.35,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.13,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M798.96,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.74,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.59,243.07c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.79,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.58,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M744.28,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M752.07,250.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M744.14,242.86c0,1.43-1.17,2.6-2.6,2.6s-2.6-1.16-2.6-2.6,1.16-2.6,2.6-2.6,2.6,1.17,2.6,2.6Z"
      />
      <path
        className="world-map-dots-2"
        d="M752.07,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M744.14,235.38c0,1.43-1.17,2.59-2.6,2.59s-2.6-1.16-2.6-2.59,1.16-2.6,2.6-2.6,2.6,1.16,2.6,2.6Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.08,242.86c0,1.43-1.16,2.6-2.59,2.6s-2.59-1.16-2.59-2.6,1.16-2.6,2.59-2.6,2.59,1.17,2.59,2.6Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.08,235.38c0,1.43-1.16,2.59-2.59,2.59s-2.59-1.16-2.59-2.59,1.16-2.6,2.59-2.6,2.59,1.16,2.59,2.6Z"
      />
      <path
        className="world-map-dots-2"
        d="M752.07,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.43,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.35,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.13,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M798.96,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.74,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.59,235.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,243.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,423.18c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,431.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,438.96c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,446.89c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.22-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,454.8c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,462.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,470.58c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,478.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,486.43c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,494.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,501.85c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M666.16,509.76c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.71,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.71,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.71,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.71,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.71,423.18c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.71,431.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.71,438.96c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.71,446.89c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.71,454.8c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.61,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.61,399.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.61,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.61,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.61,423.18c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.61,431.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.61,438.96c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.61,446.89c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.16,391.92c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.16,407.34c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.16,415.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.16,423.18c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.16,431.1c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.16,438.96c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.43,391.92c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.43,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.43,415.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.43,423.18c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.43,431.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.33,407.34c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.24-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.33,415.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.24-2.75,2.74-2.75c1.51,0,2.74,1.24,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.33,423.18c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.24-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.34,407.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.71,486.43c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.71,494.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.71,501.85c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M673.71,509.76c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,533.47c0,1.51-1.24,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,541.39c0,1.51-1.24,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,533.47c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,541.39c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,533.47c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,541.39c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,533.47c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M650.47,541.39c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,509.76c0,1.52-1.22,2.74-2.73,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.67,517.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.67,525.6c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,517.69c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5-.01,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,524.93c0,1.52-1.22,2.74-2.73,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,533.25c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,540.93c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.35,501.85c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.35,509.76c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M705.35,517.55c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73c1.52-.01,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.67,533.47c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.67,541.39c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,549.3c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,557.23c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,549.3c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,557.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,549.3c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M642.56,557.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.2,470.58c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.2,478.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M610.99,470.58c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M610.99,478.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={616.1} cy={470.58} r={2.74} />
      <circle className="world-map-dots-1" cx={577.1} cy={470.58} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M587.62,470.58c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.47,470.58c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M618.84,478.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.2,486.43c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.2,494.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M610.99,486.43c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M610.99,494.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M618.84,486.43c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <circle className="world-map-dots-1" cx={616.1} cy={494.34} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M603.2,501.85c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.2,509.76c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M610.99,501.85c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M610.99,509.76c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M618.84,501.85c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={616.1} cy={509.76} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M603.2,517.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.2,525.6c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M610.99,517.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M610.99,525.6c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.73,2.74-2.73,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M618.84,517.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M618.84,525.6c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.2,533.47c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.2,541.39c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M610.99,533.47c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M610.99,541.39c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M618.84,533.47c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M618.84,541.39c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.2,549.3c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.2,557.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M610.99,549.3c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M610.99,557.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M618.84,549.3c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M618.84,557.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.85,478.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.63,478.51c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.48,478.51c0,1.52-1.22,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.85,486.43c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.85,494.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.63,486.43c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.63,494.34c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.48,486.43c0,1.51-1.22,2.73-2.74,2.73s-2.73-1.22-2.73-2.73,1.22-2.75,2.73-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.48,494.34c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.85,501.85c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.85,509.76c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M572.48,509.76c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.63,501.85c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.63,509.76c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.48,501.85c0,1.52-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.48,509.76c0,1.52-1.22,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,289.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,297.88c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,289.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,297.88c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,289.97c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74c1.51-.01,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,297.88c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.43,289.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.43,297.88c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.21,289.97c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.53-.01,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.21,297.88c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,313.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,313.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,305.8c0,1.52-1.22,2.74-2.73,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,313.72c0,1.52-1.22,2.74-2.73,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.43,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.43,313.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.21,305.8c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.21,313.72c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,321.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,329.14c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,321.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,329.14c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,321.23c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74c1.51,0,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,329.14c0,1.52-1.22,2.75-2.73,2.75s-2.75-1.23-2.75-2.75,1.23-2.74,2.75-2.74c1.51,0,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.43,321.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.43,329.14c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.21,321.23c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.53,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.21,329.14c0,1.52-1.23,2.75-2.75,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.53,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,337.07c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,344.98c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,337.07c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,344.98c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,337.07c0,1.52-1.22,2.74-2.73,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74c1.51-.01,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,344.98c0,1.52-1.22,2.74-2.73,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74c1.51,0,2.73,1.24,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.43,337.07c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.43,344.98c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.21,337.07c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.53-.01,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.21,344.98c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.53,0,2.75,1.24,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,289.97c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,297.88c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,289.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,297.88c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.34,289.97c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={780.6} cy={297.88} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M791.26,289.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,297.88c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,289.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,297.88c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,305.8c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,313.72c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,313.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.34,305.8c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.34,313.72c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,313.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,313.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,321.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,329.14c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,321.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,329.14c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.34,321.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.34,329.14c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,321.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,329.14c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,321.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,329.14c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,289.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,297.88c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M751.77,289.97c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={749.03} cy={297.88} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M759.62,289.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.62,297.88c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,313.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M751.77,305.8c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M751.77,313.72c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.62,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.62,313.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,321.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,329.14c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M751.77,321.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M751.77,329.14c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.62,321.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.62,329.14c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M751.77,336.94c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={749.03} cy={344.86} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M759.62,336.94c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.62,344.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M704.82,289.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M704.82,297.88c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M712.6,289.97c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M712.6,297.88c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.46,289.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.46,297.88c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.38,289.97c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.38,297.88c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.16,289.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.16,297.88c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M704.82,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M704.82,313.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,289.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,297.88c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.55,297.88c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,328.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,321.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.24-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,344.41c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,336.95c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,360.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,368.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,352.65c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,313.1c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.63,305.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.55,375.82c0,1.52-1.22,2.75-2.73,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.5,0,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.4,368.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M681.31,376.1c-.16,1.51-1.5,2.61-3,2.46-1.51-.15-2.6-1.5-2.45-3.01.15-1.51,1.5-2.6,3-2.45,1.5.15,2.6,1.49,2.45,3Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,313.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M712.6,305.8c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M712.6,313.72c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.46,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.46,313.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.38,305.8c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.38,313.72c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.16,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.16,313.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M704.82,321.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M712.6,321.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M712.6,329.14c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.46,321.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.46,329.14c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.38,321.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.38,329.14c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.16,321.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.16,329.14c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,337.05c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M712.6,337.05c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.46,337.05c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.38,337.05c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.16,337.05c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,345.2c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.16,345.2c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,337.07c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,337.07c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={780.6} cy={337.07} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M783.34,344.98c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,337.07c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,344.98c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M768.11,344.98c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M776.03,344.98c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,337.07c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,344.98c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,352.85c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,360.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,352.85c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,360.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,352.85c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74c1.51,0,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,360.77c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74c1.51-.01,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.43,352.85c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.43,360.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.21,352.85c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.53,0,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.21,360.77c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.53-.01,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,368.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,376.61c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,368.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,376.61c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,368.69c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,376.61c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.43,368.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.37,360.77c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.23,352.85c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.23,360.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={796.41} cy={352.85} r={2.74} />
      <circle className="world-map-dots-1" cx={773.61} cy={352.85} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M784.27,352.85c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.15,360.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.23,368.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.23,376.61c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={796.41} cy={368.69} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M799.15,376.61c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.04,289.97c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.04,297.88c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.82,289.97c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={851.08} cy={297.88} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M861.67,289.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.67,297.88c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.59,289.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={866.85} cy={297.88} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M846.04,305.8c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.04,313.72c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.82,305.8c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.82,313.72c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.67,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.67,313.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.59,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.59,313.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.04,321.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.04,329.14c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.82,321.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.82,329.14c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.67,321.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.67,329.14c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.59,321.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.59,329.14c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.04,337.07c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.04,344.98c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={851.08} cy={337.07} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M853.82,344.98c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.67,337.07c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.67,344.98c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.24,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={866.85} cy={337.07} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M869.59,344.98c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.04,352.85c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={851.08} cy={352.85} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M861.67,352.85c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.67,360.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={866.85} cy={352.85} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M869.59,360.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={866.85} cy={368.69} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M869.59,376.61c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,274.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,282.44c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,274.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,282.44c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,274.51c0,1.52-1.22,2.74-2.73,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74c1.51,0,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,282.44c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.22-2.75-2.74,1.23-2.75,2.75-2.75,2.73,1.23,2.73,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.43,274.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.43,282.44c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.21,274.51c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.53,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.21,282.44c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.22-2.75,2.74-2.75,2.75,1.23,2.75,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,274.51c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,282.44c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,274.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,282.44c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.34,274.51c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.34,282.44c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,274.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,282.44c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,274.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,282.44c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,274.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,282.44c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M751.77,274.51c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M751.77,282.44c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.62,274.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.62,282.44c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M704.82,274.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M704.82,282.44c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M704.82,353.03c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M704.82,360.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M704.82,368.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M704.82,376.79c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M704.82,384.29c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M704.82,345.49c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M712.6,274.51c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M712.6,282.44c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.46,274.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.46,282.44c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.38,274.51c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.38,282.44c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.16,274.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.16,282.44c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,274.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,282.44c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.04,274.51c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.04,282.44c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.82,274.51c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.82,282.44c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.67,274.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.67,282.44c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.59,274.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.59,282.44c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,258.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,266.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,258.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,266.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,258.34c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74c1.51,0,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.5,266.27c0,1.51-1.22,2.74-2.73,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74c1.51-.01,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.43,258.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.43,266.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.21,258.34c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.53,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.21,266.27c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.53-.01,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,258.34c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,266.27c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,258.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,266.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.34,258.34c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.34,266.27c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,258.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,266.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,258.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,266.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,258.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,266.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M751.77,258.34c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M751.77,266.27c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.62,258.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.62,266.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.79,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.58,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.43,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.35,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.13,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M798.96,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.74,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.59,180.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.79,172.81c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.58,172.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.43,172.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.35,172.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.13,172.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M798.96,172.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.74,172.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.59,172.81c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.79,164.91c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.58,164.91c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.79,149.09c0,1.52-1.23,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={749.69} cy={149.09} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M767.58,149.09c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M744.28,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M752.07,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M744.28,172.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.49,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.49,172.81c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M721,172.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.2,172.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M752.07,172.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.43,164.91c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.35,164.91c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.13,164.91c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M798.96,164.91c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.74,164.91c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.35,156.71c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.13,156.71c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M798.96,156.71c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.74,156.71c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,219.7c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,227.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,219.7c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,227.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,219.7c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,227.62c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,219.7c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,227.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={780.6} cy={219.7} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M783.34,227.62c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,219.7c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,227.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,219.7c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,227.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,219.7c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,227.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={749.03} cy={219.7} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M751.77,227.62c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.62,219.7c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.62,227.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,204.25c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,212.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.34,204.25c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.34,212.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M751.77,204.25c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M751.77,212.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.62,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.62,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.88,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.66,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,188.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M767.7,196c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M775.49,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M783.34,188.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={780.6} cy={196} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M791.26,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M791.26,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M799.04,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M743.99,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.55,219.7c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.55,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.55,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.55,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.55,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M751.77,188.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={749.03} cy={196} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M759.62,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M759.62,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={835.51} cy={243.07} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M846.03,243.07c0,1.51-1.23,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.89,243.07c0,1.51-1.24,2.74-2.75,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.75,1.23,2.75,2.74Z"
      />
      <circle className="world-map-dots-1" cx={859.07} cy={243.07} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M869.59,243.07c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,243.07c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.25,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.03,235.17c0,1.51-1.23,2.74-2.73,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.73,1.22,2.73,2.74Z"
      />
      <circle className="world-map-dots-1" cx={820} cy={243.07} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M830.53,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.25,250.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.03,250.97c0,1.51-1.23,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.89,250.97c0,1.51-1.24,2.74-2.75,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.52.01,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.81,250.97c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.59,250.97c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.53,250.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.74,235.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.53,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.89,235.17c0,1.51-1.24,2.74-2.75,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74c1.52,0,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.81,235.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.59,235.17c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,235.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.25,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.03,180.26c0,1.51-1.23,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.89,180.26c0,1.51-1.24,2.74-2.75,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.52,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.81,180.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.59,180.26c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,180.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.25,172.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.03,172.81c0,1.51-1.23,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.89,172.81c0,1.51-1.24,2.74-2.75,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.52,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.81,172.81c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.59,172.81c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.74,180.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.53,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.74,172.81c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.53,172.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.33,219.7c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.33,227.62c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.12,219.7c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.12,227.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.16,219.7c0,1.51-1.22,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.24-2.74,2.75-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.16,227.62c0,1.51-1.22,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.24-2.74,2.75-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.95,219.7c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.95,227.62c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.8,219.7c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.8,227.62c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.72,219.7c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.72,227.62c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.5,219.7c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.5,227.62c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.45,219.7c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.45,227.62c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.23,219.7c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.23,227.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.08,219.7c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.08,227.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.33,204.25c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.33,212.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.12,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.12,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.16,204.25c0,1.52-1.22,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.24-2.74,2.75-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.16,212.17c0,1.51-1.22,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.24-2.74,2.75-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.95,204.25c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.95,212.17c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.8,204.25c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.8,212.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.72,204.25c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.72,212.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.5,204.25c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.5,212.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.45,204.25c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.45,212.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.23,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.23,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.08,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.08,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.33,188.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.33,196c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.12,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.12,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.16,188.08c0,1.52-1.22,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.24-2.74,2.75-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.16,196c0,1.52-1.22,2.74-2.74,2.74s-2.75-1.22-2.75-2.74,1.24-2.74,2.75-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.95,188.08c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.95,196c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.8,188.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.8,196c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.72,188.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.72,196c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.5,188.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.5,196c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.45,188.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.45,196c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.23,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M830.23,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.08,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M838.08,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,243.07c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={929.91} cy={243.07} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M940.44,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.26,243.07c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={953.31} cy={243.07} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M963.9,243.07c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={953.31} cy={250.39} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M963.9,250.39c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,235.17c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.44,235.17c0,1.51-1.23,2.74-2.74,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.26,235.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M956.05,235.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.9,235.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,180.26c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.44,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.26,180.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M956.05,180.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.9,180.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,172.6c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,172.6c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,172.6c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,163.23c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M917.01,219.7c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M917.01,227.62c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.8,219.7c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.8,227.62c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,219.7c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,227.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.57,219.7c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.57,227.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.35,219.7c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.35,227.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.08,219.7c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.08,227.62c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M908.93,219.7c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M908.93,227.62c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,266.2c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,266.2c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,266.2c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,258.29c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,258.29c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,258.29c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.33,250.75c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.12,250.75c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.5,250.75c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,266.2c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,266.2c0,1.51-1.23,2.73-2.75,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52,0,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,266.2c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,266.2c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.44,266.2c0,1.51-1.23,2.73-2.74,2.73s-2.75-1.22-2.75-2.73,1.23-2.74,2.75-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.26,266.2c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M956.05,266.2c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.9,266.2c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,258.29c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,258.29c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,266.2c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.5,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,258.29c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,258.29c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,258.29c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.44,258.29c0,1.52-1.23,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.26,258.29c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M956.05,258.29c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.9,258.29c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,282.38c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,282.38c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,282.38c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,274.48c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,274.48c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,274.48c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,282.38c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,282.38c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,282.38c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,282.38c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.44,282.38c0,1.52-1.23,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.26,282.38c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M956.05,282.38c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.9,282.38c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,274.48c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,274.48c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,282.38c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,274.48c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,274.48c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={929.91} cy={274.48} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M940.44,274.48c0,1.51-1.23,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.26,274.48c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={953.31} cy={274.48} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M963.9,274.48c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.52,243.07c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.52,250.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.52,266.2c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.52,258.29c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={984.58} cy={250.39} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M987.32,258.29c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.06,243.25c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.42,258.29c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.52,274.48c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,298.19c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,298.19c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,298.19c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,290.28c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,290.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,290.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,298.19c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,298.19c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,298.19c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,298.19c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.44,298.19c0,1.52-1.23,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M956.05,298.19c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.9,298.19c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.66,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.52,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1003.13,274.48c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.23-2.74,2.73-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1003.13,289.41c0,1.52-1.23,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.23-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.52,274.48c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1003.13,298.19c0,1.52-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.23-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1002.89,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.87,305.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M987.32,305.8c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,290.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,290.28c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,298.19c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,290.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,290.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={929.91} cy={290.28} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M940.44,290.28c0,1.51-1.23,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,313.63c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,313.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,313.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,305.72c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,305.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,305.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,313.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,313.63c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,313.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,313.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.44,313.63c0,1.51-1.23,2.74-2.74,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,305.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,305.72c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,313.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.5-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,305.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,305.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,305.72c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.44,305.72c0,1.52-1.23,2.74-2.74,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,329.81c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,329.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,329.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,321.91c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,321.91c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,321.91c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,329.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,329.81c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,329.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={929.91} cy={329.81} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M940.44,329.81c0,1.51-1.23,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,321.91c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,321.91c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,329.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,321.91c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,321.91c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,321.91c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.44,321.91c0,1.51-1.23,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,345.62c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,345.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,345.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,337.72c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,337.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,337.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,345.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,345.62c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,345.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={929.91} cy={345.62} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M940.44,345.62c0,1.51-1.23,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,337.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,337.72c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,345.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,337.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,337.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,337.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.44,337.72c0,1.51-1.23,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.05,329.81c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M954.49,329.81c0,1.51-1.22,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M958.3,353.53c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.86,375.96c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.86,384.15c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.47,392.65c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.67,399.49c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={970.2} cy={407.39} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M955.96,407.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.38,415.3c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M986.11,415.3c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.05,321.91c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.05,345.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.05,337.72c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,360.47c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,360.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,360.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,352.56c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,352.56c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,352.56c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,360.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,360.47c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,360.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,352.56c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,352.56c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,360.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,352.56c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,352.56c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={929.91} cy={352.56} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M940.44,352.56c0,1.52-1.23,2.74-2.74,2.74s-2.75-1.22-2.75-2.74,1.23-2.74,2.75-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.12,485.85c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.24-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M994.94,445.15c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M994.94,438.13c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1002.95,501.28c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.81,501.28c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M987.44,501.28c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.81,493.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.73,493.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.12,517.47c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.12,509.57c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.17,517.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1002.95,517.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.81,517.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.73,517.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.51,454.23c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.99,454.23c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.17,509.57c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1002.95,509.57c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M987.44,517.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M987.44,509.57c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.81,509.57c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.73,509.57c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.12,533.28c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.12,525.37c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.17,533.28c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1002.95,533.28c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.81,533.28c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.73,533.28c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.17,525.37c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1002.95,525.37c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M987.44,533.28c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M987.44,525.37c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.81,525.37c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.73,525.37c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.51,462.13c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.6,462.13c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.12,548.13c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.12,540.22c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.17,548.13c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1002.95,548.13c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.81,548.13c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.17,540.22c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1002.95,540.22c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M987.44,548.13c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M987.44,540.22c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.81,540.22c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.73,540.22c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.12,556.4c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.17,556.4c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1002.95,556.4c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.81,556.4c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.73,556.4c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.51,556.4c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M987.44,556.4c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.12,563.93c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.12,571.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.81,517.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.81,533.28c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.81,525.37c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.81,548.13c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.81,540.22c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.81,556.4c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.81,563.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.81,571.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.61,533.28c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.61,525.37c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.61,548.13c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.61,540.22c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.61,556.4c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.61,563.93c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.61,571.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M955.71,533.28c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M955.71,525.37c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M955.71,548.13c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M955.71,540.22c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M955.71,556.4c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.75,1.24,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M955.71,563.93c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M955.71,571.84c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.09,533.28c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.09,548.13c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.09,540.22c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.09,556.4c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.09,563.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.09,571.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.19,533.28c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.19,548.13c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.19,540.22c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.19,556.4c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.19,563.93c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.19,571.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.28,548.13c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.28,540.22c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.28,556.4c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.28,563.93c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.28,571.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.09,580.03c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.19,580.03c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.28,580.03c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.17,571.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1002.95,571.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.81,571.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.73,571.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.51,571.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.17,563.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1002.95,563.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M987.44,571.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M987.44,563.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.81,563.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.73,563.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.51,563.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.81,588.02c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.73,588.02c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.51,588.02c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.17,580.11c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.22-2.74-2.75,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.81,580.11c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.22-2.74-2.75,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.73,580.11c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.22-2.74-2.75,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.51,580.11c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.22-2.74-2.75,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.81,595.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.73,595.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.51,595.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.73,610.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1066.15,485.85c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.24-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1074.06,485.85c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.24-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1050.51,501.28c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.78,501.28c0,1.52-1.22,2.74-2.73,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.78,493.38c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.47,454.23c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1050.51,517.47c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1050.51,509.57c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.78,517.47c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.78,509.57c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.47,470.04c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.47,462.13c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1050.51,533.28c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1058.29,533.28c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1050.51,525.37c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1058.29,525.37c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.78,533.28c0,1.52-1.22,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.78,525.37c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.47,476.98c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.08,470.04c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.08,462.13c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.08,476.98c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1049.93,470.04c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1049.93,462.13c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1049.93,476.98c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1057.89,470.04c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1057.89,476.98c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1065.5,476.98c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1073.36,470.04c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1081.85,462.13c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1087.71,470.63c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1050.51,548.13c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1058.29,548.13c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1066.15,548.13c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1050.51,540.22c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1058.29,540.22c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.78,548.13c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.36,548.13c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.32,517.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.24,517.47c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.32,533.28c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.24,533.28c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.32,525.37c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.24,525.37c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.32,548.13c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.32,540.22c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.24,540.22c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1033.88,548.13c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.78,540.22c0,1.52-1.22,2.74-2.73,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1066.15,540.22c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.47,556.4c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1050.51,556.4c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1058.29,556.4c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1066.15,556.4c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.24,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.78,556.4c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.73,1.24,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.47,571.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.47,563.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1050.51,571.84c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1058.29,571.84c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1050.51,563.93c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1058.29,563.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.78,571.84c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.78,563.93c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1066.15,563.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.47,588.02c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.47,580.11c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.22-2.74-2.75,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1050.51,580.11c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.22-2.74-2.75,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.78,588.02c0,1.52-1.22,2.74-2.73,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1042.78,580.11c0,1.52-1.22,2.75-2.73,2.75s-2.74-1.22-2.74-2.75,1.23-2.74,2.74-2.74c1.51.01,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.47,595.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1064.08,617.26c0,1.36-1.1,2.47-2.47,2.47s-2.48-1.11-2.48-2.47,1.11-2.47,2.48-2.47c1.37,0,2.47,1.1,2.47,2.47Z"
      />
      <path
        className="world-map-dots-2"
        d="M1071.11,617.26c0,1.36-1.11,2.47-2.47,2.47s-2.48-1.11-2.48-2.47,1.11-2.47,2.48-2.47c1.36,0,2.47,1.1,2.47,2.47Z"
      />
      <path
        className="world-map-dots-2"
        d="M1056.93,624.73c0,1.36-1.1,2.47-2.47,2.47s-2.47-1.11-2.47-2.47,1.1-2.48,2.47-2.48,2.47,1.11,2.47,2.48Z"
      />
      <path
        className="world-map-dots-2"
        d="M1050.86,624.73c0,1.36-1.1,2.47-2.47,2.47s-2.47-1.11-2.47-2.47,1.11-2.48,2.47-2.48,2.47,1.11,2.47,2.48Z"
      />
      <path
        className="world-map-dots-2"
        d="M1099.78,595.86c0,1.36-1.11,2.47-2.47,2.47s-2.47-1.11-2.47-2.47,1.11-2.48,2.47-2.48,2.47,1.12,2.47,2.48Z"
      />
      <path
        className="world-map-dots-2"
        d="M1091.85,540.4c0,1.36-1.11,2.47-2.47,2.47s-2.47-1.11-2.47-2.47,1.11-2.47,2.47-2.47c1.37-.01,2.47,1.1,2.47,2.47Z"
      />
      <path
        className="world-map-dots-2"
        d="M1086.04,532.74c0,1.37-1.1,2.48-2.47,2.48s-2.47-1.11-2.47-2.48,1.1-2.47,2.47-2.47c1.37-.01,2.47,1.1,2.47,2.47Z"
      />
      <path
        className="world-map-dots-2"
        d="M1092.76,603.33c0,1.36-1.11,2.47-2.47,2.47s-2.47-1.11-2.47-2.47,1.11-2.48,2.47-2.48c1.36,0,2.47,1.12,2.47,2.48Z"
      />
      <path
        className="world-map-dots-2"
        d="M1078.28,610.12c0,1.37-1.11,2.47-2.47,2.47s-2.47-1.1-2.47-2.47,1.11-2.47,2.47-2.47,2.47,1.11,2.47,2.47Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,376.65c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,376.65c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,376.65c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M877.42,368.75c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,368.75c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,368.75c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,376.65c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,376.65c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,368.75c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M939.97,431.4c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M947.76,431.4c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M955.61,431.4c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M955.61,423.79c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M939.97,447.21c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M947.76,447.21c0,1.52-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M955.61,447.21c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.51,447.21c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.51,454.81c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M970.89,454.81c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.51,462.14c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.42,462.14c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M939.97,439.3c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M947.76,439.3c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.25,447.21c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.25,439.3c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M955.61,439.3c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M939.97,454.15c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M947.76,454.15c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.25,454.15c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M939.97,477.57c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.25,477.57c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.74,470.55c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,392.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,392.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,384.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,384.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.52,439.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={890.56} cy={439.23} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M901.15,439.23c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.15,454.08c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.3,446.17c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.15,446.17c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.15,462.35c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M908.76,454.08c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M908.76,462.35c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,392.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,392.46c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,384.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,384.55c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,392.46c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,384.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,399.78c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,399.78c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M916.89,399.78c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.73,399.78c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,399.78c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,415.96c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,408.06c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M885.2,431.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M893.06,423.87c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M909.1,407.76c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,431.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.38,423.87c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <circle className="world-map-dots-1" cx={953.31} cy={290.28} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M917.01,250.75c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.8,250.75c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,250.75c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.57,250.75c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.35,250.75c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.08,250.75c0,1.52-1.23,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M908.93,250.75c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M956.18,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M956.18,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.96,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.96,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M917.01,204.25c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M917.01,212.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.8,204.25c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.8,212.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.57,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.57,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.35,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.35,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.08,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.08,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M908.93,204.25c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M908.93,212.17c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M956.18,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M956.18,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.96,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M963.96,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M917.01,188.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M917.01,196c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.8,188.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M924.8,196c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M932.65,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.57,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M940.57,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.35,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M948.35,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.63,180.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.48,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M987.4,180.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.18,180.26c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1003.01,180.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.8,180.26c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.92,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.92,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.71,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.71,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.71,227.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.71,235.89c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.76,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.76,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.54,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.54,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M987.4,204.25c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.31,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1003.1,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.92,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.92,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.71,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1018.71,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.32,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.23,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1041.54,204.25c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1049.89,195.91c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.32,212.17c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1003.32,219.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1011.1,219.78c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1010.92,227.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1011.1,235.3c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.32,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1034.13,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1026.32,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.76,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M971.76,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.54,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M979.54,196c0,1.52-1.23,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M987.4,188.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M987.4,196c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.31,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M995.31,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1003.1,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M1003.1,196c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.08,188.08c0,1.52-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M901.08,196c0,1.52-1.23,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M908.93,188.08c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M908.93,196c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M704.82,258.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.27,258.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M704.82,266.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M712.6,258.34c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M712.6,266.27c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.46,258.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.46,266.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.38,258.34c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.38,266.27c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.16,258.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.16,266.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,266.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,353.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,361.01c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,368.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,376.85c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M689.55,383.62c0,1.51-1.22,2.73-2.73,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,384.65c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.42,353.03c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.42,360.94c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.42,368.86c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.42,376.79c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M720.42,384.29c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.04,353.1c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.04,361.01c0,1.52-1.23,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.04,368.93c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.04,376.85c0,1.52-1.23,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M736.35,360.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={733.61} cy={368.86} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M728.99,361.01c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={733.61} cy={352.93} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M728.99,353.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.99,344.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.99,368.93c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M728.99,376.85c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M713.04,384.65c0,1.51-1.23,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.75,2.73-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,337.65c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,345.56c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.27,321.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M697.45,329.4c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.04,258.34c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M846.04,266.27c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.82,258.34c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M853.82,266.27c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.67,258.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M861.67,266.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.59,258.34c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M869.59,266.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M798.96,384.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.75,384.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.6,384.47c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M822.33,415.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M798.97,392.39c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.75,392.39c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.6,392.39c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.75,400.3c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M806.75,408.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.6,400.3c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73c1.52,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M814.6,408.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.85,517.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M579.85,525.6c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.63,517.69c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.63,525.6c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.48,517.69c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.48,525.6c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.73,2.73-2.73,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.63,533.47c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.63,541.39c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.48,533.47c0,1.51-1.22,2.73-2.74,2.73s-2.73-1.22-2.73-2.73,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.48,541.39c0,1.51-1.22,2.73-2.74,2.73s-2.73-1.22-2.73-2.73,1.22-2.74,2.73-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.63,549.3c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M587.63,557.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.75,2.74-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.48,549.3c0,1.52-1.22,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.48,557.23c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.75,2.73-2.75,2.74,1.23,2.74,2.75Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,564.59c0,1.51-1.24,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M626.93,572.51c0,1.52-1.24,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M634.7,564.59c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.2,564.59c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.2,572.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M610.99,564.59c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M610.99,572.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M618.84,564.59c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M618.84,572.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M603.2,580.43c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M610.99,580.43c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M618.84,580.43c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.48,564.59c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.48,572.51c0,1.52-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M595.48,580.43c0,1.51-1.22,2.73-2.74,2.73s-2.73-1.22-2.73-2.73,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,477.77c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,485.68c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,477.77c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,485.68c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,477.77c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,485.68c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,477.77c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,485.68c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.94,477.77c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.94,485.68c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,493.6c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,501.52c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,493.6c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,501.52c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,493.6c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,501.52c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,493.6c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,501.52c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.94,493.6c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.94,501.52c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,509.02c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,516.95c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,509.02c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,516.95c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,509.02c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,516.95c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,509.02c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,516.95c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.94,509.02c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.94,516.95c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,524.86c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,532.78c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,524.86c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,532.78c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,524.86c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,532.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,524.86c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,532.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.94,524.86c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.94,532.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,477.77c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,485.68c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,477.77c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,485.68c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.07,477.77c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.07,485.68c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.99,477.77c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.99,485.68c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,477.77c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,485.68c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,493.6c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,501.52c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,493.6c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,501.52c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.07,493.6c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.07,501.52c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.99,493.6c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.99,501.52c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,493.6c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,501.52c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,509.02c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,516.95c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,509.02c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,516.95c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.07,509.02c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.07,516.95c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.99,509.02c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.99,516.95c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,509.02c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,516.95c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.72,477.77c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.72,485.68c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.5,477.77c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.5,485.68c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.35,477.77c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.35,485.68c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.72,493.6c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.72,501.52c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.5,493.6c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.5,501.52c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.35,493.6c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.35,501.52c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.5,509.02c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.35,509.02c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M250.89,477.77c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M250.89,485.68c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,524.86c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,524.86c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.07,524.86c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.07,532.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.99,524.86c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.99,532.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.29,532.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.21,532.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,524.86c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,532.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,540.64c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,548.57c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,540.64c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,548.57c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,540.64c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,548.57c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,540.64c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,548.57c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.94,540.64c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.94,548.57c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,556.48c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,564.4c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,556.48c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,564.4c0,1.52-1.22,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,556.48c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,564.4c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,556.48c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,564.4c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.11,548.57c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M281.71,548.57c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.67,548.57c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.95,540.64c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.95,548.57c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.87,540.64c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298,540.64c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.36,540.64c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.28,540.64c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.87,548.57c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.11,556.48c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.95,556.48c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.37,556.48c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M281.71,556.48c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.95,564.4c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.87,556.48c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.87,564.4c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M297.87,564.4c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.22,564.4c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.14,564.4c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.94,556.48c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.94,564.4c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.77,477.77c0,1.51-1.22,2.73-2.74,2.73s-2.73-1.22-2.73-2.73,1.22-2.74,2.73-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.77,485.68c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M368.55,477.77c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M368.55,485.68c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M376.4,477.77c0,1.51-1.23,2.73-2.75,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M376.4,485.68c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M384.32,477.77c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M384.32,485.68c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.77,493.6c0,1.52-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.77,501.52c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M368.55,493.6c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M368.55,501.52c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M376.4,493.6c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M376.4,501.52c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M384.32,493.6c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M384.32,501.52c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.77,509.02c0,1.52-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.77,516.95c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M368.55,509.02c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M368.55,516.95c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M376.4,509.02c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M376.4,516.95c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M384.32,509.02c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M384.32,516.95c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.77,524.86c0,1.52-1.22,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.77,532.78c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M368.55,524.86c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M368.55,532.78c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M376.4,524.86c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M376.4,532.78c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M384.32,524.86c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M384.32,532.78c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.77,540.64c0,1.52-1.22,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.77,548.57c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.77,556.48c0,1.52-1.22,2.74-2.74,2.74s-2.73-1.22-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,462.31c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,470.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={326.64} cy={462.31} r={2.74} />
      <circle className="world-map-dots-1" cx={326.64} cy={470.23} r={2.74} />
      <circle className="world-map-dots-1" cx={334.49} cy={462.31} r={2.74} />
      <circle className="world-map-dots-1" cx={334.49} cy={470.23} r={2.74} />
      <circle className="world-map-dots-1" cx={342.41} cy={462.31} r={2.74} />
      <circle className="world-map-dots-1" cx={342.41} cy={470.23} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M352.94,462.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.94,470.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,462.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,470.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,462.31c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,470.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={295.33} cy={462.31} r={2.74} />
      <circle className="world-map-dots-1" cx={295.33} cy={470.23} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M305.99,462.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.99,470.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,462.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,470.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.72,462.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.72,470.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.5,462.31c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.5,470.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.35,462.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.35,470.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.1,462.31c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.1,470.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={248.15} cy={462.31} r={2.74} />
      <circle className="world-map-dots-1" cx={248.15} cy={470.23} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M360.77,462.31c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.77,470.23c0,1.51-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.74,2.73-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={365.81} cy={462.31} r={2.74} />
      <circle className="world-map-dots-1" cx={365.81} cy={470.23} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M376.4,462.31c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M376.4,470.23c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M384.32,462.31c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M384.32,470.23c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M391.41,477.77c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M391.41,485.68c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M391.41,493.6c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M391.41,462.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M391.41,470.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M399.91,477.77c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M399.91,485.68c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M399.91,470.23c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,446.14c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,454.06c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73c1.51,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,446.14c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,454.06c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73c1.52,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,446.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,454.06c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73c1.52,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,446.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,454.06c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73c1.52,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.94,446.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.94,454.06c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73c1.51,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,446.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,454.06c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73c1.51,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,446.14c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,454.06c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73c1.51,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.07,446.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.07,454.06c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.99,446.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.99,454.06c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.73,2.74-2.73c1.51,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,446.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,454.06c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73c1.51,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.72,446.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.72,454.06c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73c1.51,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.5,446.14c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.5,454.06c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73c1.51,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.35,446.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.35,454.06c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73c1.52,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.1,446.14c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.1,454.06c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73c1.52,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M250.89,446.14c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M250.89,454.06c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.73,2.74-2.73c1.51,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.77,454.06c0,1.52-1.22,2.74-2.74,2.74s-2.73-1.23-2.73-2.74,1.22-2.73,2.73-2.73c1.51,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,313.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,321.66c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,313.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,321.66c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,313.73c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,321.66c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.48,313.73c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.48,321.66c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M251.27,313.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M251.27,321.66c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.5-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,329.58c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.48,329.58c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={240.74} cy={337.49} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M251.06,360.42c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.63,360.42c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.75,1.22,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M273.98,368.39c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,313.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,321.66c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,313.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,321.66c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M196.39,313.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M196.39,321.66c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,313.73c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,321.66c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M212.1,313.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M212.1,321.66c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M148.86,329.58c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M148.86,337.49c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,329.58c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,337.49c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M196.39,329.58c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,329.58c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M212.1,329.58c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,345c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,352.92c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,313.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,321.66c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M164.83,313.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M164.83,321.66c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,313.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,321.66c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M132.93,329.58c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M132.93,337.49c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M140.78,329.58c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.23-2.74-2.73,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,345c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,345c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,313.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,321.66c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.45,313.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.45,321.66c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M133.63,313.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M125.48,313.73c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M133.63,321.66c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M259.1,313.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,298.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,306.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,298.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,306.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,298.28c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,306.21c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.48,298.28c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.48,306.21c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M251.27,298.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M251.27,306.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,298.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,306.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,298.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,306.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M196.39,298.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M196.39,306.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,298.28c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,306.21c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M212.1,298.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M212.1,306.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,298.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,306.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M164.83,298.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M164.83,306.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,344.36c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,352.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M164.83,344.36c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M164.83,352.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,328.91c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,336.83c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={162.09} cy={328.91} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M164.83,336.83c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,298.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,306.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.43,298.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.43,306.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,298.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,306.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M259.1,298.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M259.1,306.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.67,298.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.67,306.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,282.11c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,290.04c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,282.11c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,290.04c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,282.11c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,290.04c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={240.74} cy={282.11} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M243.48,290.04c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={248.52} cy={282.11} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M251.27,290.04c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74c1.5,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,282.11c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,290.04c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,282.11c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,290.04c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={193.65} cy={282.11} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M196.39,290.04c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,282.11c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,290.04c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={209.35} cy={282.11} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M212.1,290.04c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,282.11c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,290.04c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={162.09} cy={282.11} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M164.83,290.04c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={169.94} cy={282.11} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M172.68,290.04c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.43,282.11c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.43,290.04c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,282.11c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,290.04c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,266.39c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,274.31c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.48,266.39c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.48,274.31c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M251.27,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M251.27,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M196.39,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M196.39,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,266.39c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,274.31c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M212.1,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M212.1,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M164.83,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M164.83,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.45,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.45,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M133.63,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M133.63,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M125.87,274.31c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,250.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,258.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,250.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,258.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,250.94c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,258.86c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={240.74} cy={250.94} r={2.74} />
      <circle className="world-map-dots-1" cx={240.74} cy={258.86} r={2.74} />
      <circle className="world-map-dots-1" cx={248.52} cy={250.94} r={2.74} />
      <circle className="world-map-dots-1" cx={248.52} cy={258.86} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M180.76,250.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,258.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,250.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,258.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={193.65} cy={250.94} r={2.74} />
      <circle className="world-map-dots-1" cx={193.65} cy={258.86} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M204.31,250.94c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,258.86c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={209.35} cy={250.94} r={2.74} />
      <circle className="world-map-dots-1" cx={209.35} cy={258.86} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M157.05,250.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,258.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={162.09} cy={250.94} r={2.74} />
      <circle className="world-map-dots-1" cx={162.09} cy={258.86} r={2.74} />
      <circle className="world-map-dots-1" cx={169.94} cy={250.94} r={2.74} />
      <circle className="world-map-dots-1" cx={169.94} cy={258.86} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M141.43,250.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.43,258.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,250.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,258.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,234.77c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,242.69c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.48,234.77c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.48,242.69c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M251.27,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M251.27,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M196.39,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M196.39,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,234.77c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,242.69c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M212.1,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M212.1,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M164.83,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M164.83,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.43,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.43,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,219.63c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,227.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,219.63c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,227.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,219.63c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,227.55c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.48,219.63c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={240.74} cy={227.55} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M251.27,219.63c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={248.52} cy={227.55} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M180.76,219.63c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,227.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,219.63c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,227.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M196.39,219.63c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={193.65} cy={227.55} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M204.31,219.63c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,227.55c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M212.1,219.63c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={209.35} cy={227.55} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M157.05,219.63c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,227.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M164.83,219.63c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={162.09} cy={227.55} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M172.68,219.63c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={169.94} cy={227.55} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M149.21,219.63c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,227.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.45,219.63c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.45,227.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,204.18c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,212.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,204.18c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,212.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,204.18c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,212.1c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={240.74} cy={204.18} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M243.48,212.1c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={248.52} cy={204.18} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M251.27,212.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,204.18c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,212.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,204.18c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,212.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={193.65} cy={204.18} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M196.39,212.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,204.18c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,212.1c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={209.35} cy={204.18} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M212.1,212.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,204.18c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,212.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={162.09} cy={204.18} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M164.83,212.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={169.94} cy={204.18} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M172.68,212.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.43,204.18c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.43,212.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,204.18c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,212.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.71,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,188.01c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.56,195.93c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.48,188.01c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.48,195.93c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M251.27,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.5,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M251.27,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.5,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M196.39,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M196.39,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,188.01c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,195.93c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M212.1,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.93,180.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.54,180.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M196.39,180.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.31,180.47c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M212.1,180.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M212.1,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.21,282.11c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={287.26} cy={282.11} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M258.68,282.11c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.6,282.11c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.38,282.11c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.21,290.09c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.6,290.09c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.38,290.09c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.21,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.21,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M297.85,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M297.85,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.77,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.55,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.68,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.68,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.6,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.6,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.38,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.38,274.31c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.21,250.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.21,258.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={287.26} cy={250.94} r={2.74} />
      <circle className="world-map-dots-1" cx={287.26} cy={258.86} r={2.74} />
      <circle className="world-map-dots-1" cx={295.11} cy={250.94} r={2.74} />
      <circle className="world-map-dots-1" cx={295.11} cy={258.86} r={2.74} />
      <circle className="world-map-dots-1" cx={303.03} cy={250.94} r={2.74} />
      <circle className="world-map-dots-1" cx={303.03} cy={258.86} r={2.74} />
      <circle className="world-map-dots-1" cx={310.81} cy={250.94} r={2.74} />
      <circle className="world-map-dots-1" cx={310.81} cy={258.86} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M258.68,250.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.68,258.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.6,250.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.6,258.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.38,250.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.38,258.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M297.85,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M297.85,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.77,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.77,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.55,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.55,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.23,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.15,266.39c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.23,250.94c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.23,258.86c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.23,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.23,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.15,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.15,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M336.93,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M336.93,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.89,250.94c0,1.52-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.89,258.86c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.67,258.86c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M344.96,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M344.96,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.89,234.77c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.89,242.69c0,1.51-1.23,2.74-2.75,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.75,1.23,2.75,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M360.67,234.77c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.23,227.24c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.15,227.24c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M336.93,227.24c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M344.96,227.24c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.23,219.45c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={326.41} cy={219.45} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M336.93,219.45c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M344.96,219.45c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.23,212.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={326.41} cy={212.01} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M360.67,242.69c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.68,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.68,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.6,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.6,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.38,234.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.38,242.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.55,219.63c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={310.81} cy={227.55} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M336.97,196.73c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M344.89,196.73c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M344.89,204.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={349.93} cy={196.73} r={2.74} />
      <circle className="world-map-dots-1" cx={349.93} cy={188.81} r={2.74} />
      <circle className="world-map-dots-1" cx={357.97} cy={188.81} r={2.74} />
      <circle className="world-map-dots-1" cx={349.93} cy={181.19} r={2.74} />
      <circle className="world-map-dots-1" cx={357.97} cy={181.19} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M344.89,181.19c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.23,173.05c0,1.51-1.22,2.74-2.74,2.74s-2.75-1.23-2.75-2.74,1.23-2.74,2.75-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.53,173.05c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.45,173.05c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.68,219.63c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.68,227.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.6,227.55c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.68,204.18c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.68,212.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.6,204.18c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.21,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.21,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M297.85,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.55,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.68,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.68,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.6,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.6,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.38,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.48,180.47c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M251.27,180.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.5.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M297.85,180.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.6,180.47c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M251.27,172.33c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.68,172.33c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.38,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M164.83,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M164.83,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.43,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.43,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M133.31,204.18c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M133.31,212.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M117.7,204.18c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M125.48,204.18c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M125.48,212.1c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M133.31,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M133.31,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M117.7,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M117.7,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M125.48,188.01c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M125.48,195.93c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M109.54,204.18c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={91.18} cy={204.18} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M101.71,204.18c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M109.54,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M109.54,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M93.92,195.93c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M101.71,188.01c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,180.12c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M164.83,180.12c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,180.12c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M141.43,180.12c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M149.21,180.12c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M133.31,180.12c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M117.7,180.12c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M125.48,180.12c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M109.54,180.12c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M101.71,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={83.41} cy={204.18} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M70.54,204.18c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M78.32,204.18c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M86.15,195.93c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={91.18} cy={211.87} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M101.71,211.87c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M70.54,220.12c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M78.32,220.12c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.52,0,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={83.41} cy={211.87} r={2.74} />
      <circle className="world-map-dots-1" cx={75.62} cy={211.87} r={2.74} />
      <circle className="world-map-dots-1" cx={67.85} cy={211.87} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M125.49,298.28c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M125.49,306.21c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M133.27,298.28c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M133.27,306.21c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M125.49,282.11c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M125.49,290.04c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M118.05,290.04c0,1.51-1.22,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M133.27,282.11c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M133.27,290.04c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,376.79c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,376.79c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,360.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.24-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,368.54c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,360.62c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.24-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M157.05,368.54c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={162.09} cy={360.62} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M164.83,368.54c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={169.94} cy={360.62} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M141.11,353.36c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,353.36c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,329.41c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M180.76,337.33c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,329.41c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,337.77c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M172.68,368.54c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M211.86,368.59c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M218.63,368.59c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={215.89} cy={360.62} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M211.86,376.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M211.86,384.34c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M211.86,392.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.08,384.34c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M196.11,384.34c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M204.08,392.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.61,384.34c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.61,392.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.83,384.34c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.73,2.74-2.73c1.51,0,2.74,1.22,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.83,392.26c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.61,399.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M227.61,407.61c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M219.83,399.69c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.14,368.59c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M188.14,376.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M195.92,376.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M203.78,376.51c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M259.1,290.04c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M368.55,454.06c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.73,2.74-2.73c1.52,0,2.74,1.21,2.74,2.73Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,438.69c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,438.69c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,438.69c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,438.69c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,438.69c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,438.69c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.07,438.69c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.99,438.69c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,438.69c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.72,438.69c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.5,438.69c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.35,438.69c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.1,438.69c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M250.89,438.69c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,430.78c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,430.78c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.6,423.15c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.38,423.15c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,430.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M345.15,430.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,430.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,430.78c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.07,430.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.99,430.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,430.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.72,430.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.5,430.78c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.35,430.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M250.89,430.78c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,423.24c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,423.24c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.07,423.24c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.99,423.24c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,423.24c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.99,415.36c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.77,415.36c0,1.51-1.23,2.73-2.74,2.73s-2.74-1.22-2.74-2.73,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.72,423.24c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.5,423.24c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.35,423.24c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M250.89,423.24c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,414.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,414.97c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.07,414.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.72,414.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.5,414.97c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.35,414.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,407.18c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M282.43,399.38c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M290.22,407.18c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M298.07,407.18c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.16,407.18c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M258.72,407.18c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.5,407.18c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M266.5,399.38c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M274.35,407.18c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M250.89,414.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M243.8,414.97c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M235.66,414.97c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.23,422.65c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.05,572.27c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.05,580.19c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.73,1.22,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M344.84,572.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M344.84,580.19c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M352.69,572.27c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={310.95} cy={572.27} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M321.48,572.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.5-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.32,572.27c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.05,588.1c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M337.05,596.02c0,1.51-1.22,2.74-2.73,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.73,1.23,2.73,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.74,603.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M344.84,650.99c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.69,580.19c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.48,580.19c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.33,580.19c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.69,588.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.69,596.02c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.48,588.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.48,596.02c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.33,588.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <circle className="world-map-dots-1" cx={287.21} cy={572.27} r={2.74} />
      <path
        className="world-map-dots-2"
        d="M297.74,572.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.59,572.27c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M289.96,580.19c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M297.74,580.19c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.6,580.19c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M289.96,588.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M297.74,588.1c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.6,588.1c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.33,596.02c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.69,603.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.8,603.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M297.75,596.02c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.54,596.02c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.22-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M297.75,603.53c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74c1.52,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M297.74,610.95c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M297.74,618.87c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M297.75,626.79c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.79,618.87c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.8,626.79c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M297.75,634.29c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.55,634.29c0,1.52-1.22,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M289.96,603.53c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.69,611.45c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M306.33,611.45c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M289.85,595.68c0,1.51-1.22,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.69,619.36c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.69,627.29c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.69,634.64c0,1.52-1.23,2.75-2.74,2.75s-2.74-1.23-2.74-2.75,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M305.8,641.94c0,1.51-1.23,2.74-2.74,2.74s-2.74-1.23-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.69,642.44c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.52-.01,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M313.69,649.8c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.48,635.15c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.22,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M321.48,658.9c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
      <path
        className="world-map-dots-2"
        d="M329.33,658.9c0,1.52-1.23,2.74-2.74,2.74s-2.74-1.22-2.74-2.74,1.23-2.74,2.74-2.74c1.51,0,2.74,1.23,2.74,2.74Z"
      />
    </g>
  </svg>
);
export default WorldMapDots;
