import * as React from "react"
const DentalIcon = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 472.97 561.09"
    className={className}
  >
    <defs>
      <style>
        {
          ".dental-icon-2{fill:#fff}.dental-icon-3{fill:#fefefe}.dental-icon-4{fill:#fdfefe}.dental-icon-5{fill:#142a54}"
        }
      </style>
    </defs>
    <path
      d="M446.63 243.56c12.4 32.65 13.44 65.47.45 98.12-18.32 46.06-52.5 73.31-100.57 84.19-11.06 2.5-22.54-.63-33.26 3.7-2.46-2.17-1.74-5.02-1.6-7.71 1.3-24.76-.86-49.36-2.71-74.01-1.34-17.86-2.28-35.9 2.54-53.57-5.21 19.39-3.62 39.12-2.5 58.72 1.13 19.79 3.67 39.5 2.83 59.38-.23 5.43-.15 11.1-4.68 15.29-33.7-5.14-63.04-18.98-85.67-44.89-31.39-35.93-40.87-77.56-29.21-123.62 11.23-44.34 39.91-73.65 81.44-91.18 1.38-.58 2.92-.79 4.38-1.17-.18 7.21-5.78 11.12-9.87 15.84-9.11 10.49-17.17 21.4-19.92 35.53-2.45 12.59.77 23.83 7.83 33.85 9.95 14.12 24.56 13.05 39.04 9.99 9.85-2.09 19.46-5.39 28.86-8.98 15.99-6.1 31.6-5.2 47.55.07 13 4.3 25.62 9.82 39.72 10.53 13.02.65 22.41-4.42 29.45-14.89 1.51-2.24 2.6-4.97 5.9-5.18Z"
      style={{
        fill: "#98ccef",
      }}
    />
    <path
      d="M307.13 427.67c4.43-24.07.77-48.03-.79-72.04-1.2-18.56-2.55-37.16.72-55.67.26-1.5.48-3.05 1.07-4.43 1.37-3.19 1.58-9.19 5.65-8.11 5.72 1.51.83 6.12.1 9.06-4.44 17.81-3.07 35.77-1.92 53.72 1.69 26.44 5.13 52.85 1.28 79.38-5.9 38.8-13.24 77.18-33.33 111.73-2.6 4.46-5.7 8.74-9.14 12.59-9.61 10.75-17.91 9.44-23.55-3.69-5.48-12.77-7.2-26.51-9.6-40.04-5.82-32.83-11.67-65.64-24.18-96.78-4.36-10.84-9.48-21.28-17.97-29.58-11.68-11.43-24.15-11.24-35.04.94-12.78 14.29-18.61 32.13-24.1 49.98-8.93 29.01-14.01 58.85-18.23 88.85-1.11 7.89-2.18 15.79-5.64 23.06-5.37 11.3-14.23 13.18-23.55 4.95-7.46-6.6-12.83-14.84-16.63-23.94-22.41-53.57-31.16-109.05-23.03-166.87 2.04-14.52 4.75-29.01 4.26-43.77-.44-13.46-4.86-25.64-11.48-37.29-19.48-34.29-33.8-70.27-40.53-109.47C-12.57 88.29 75.51 37.73 133.86 65.09c12.67 5.94 25.39 12.14 39.01 15.86 8.34 2.28 16.83 3.05 25.45 1.91 2.5-.33 5.52-.71 5.85 2.52.34 3.31-2.97 3.38-5.29 3.63-13.69 1.45-26.71-1.05-39.42-6.26-15.23-6.25-29.83-14.09-46.21-17.44C55.31 53.46-.19 104.75 6.85 163.43c4.53 37.69 16.97 72.87 36.24 105.04 15.86 26.47 18.79 53.51 13.58 82.84-10.41 58.52-2.4 115.07 19.25 169.99 3.73 9.46 8.98 18.12 16.42 25.19 6.56 6.24 10.91 5.44 14.74-2.81 5.39-11.62 5.27-24.43 7.3-36.75 5.39-32.62 11.56-65.01 23.81-95.92 3.5-8.84 7.74-17.31 13.29-25.05 15.63-21.8 36.15-22.32 52.89-1.18 10.02 12.66 15.65 27.54 20.62 42.71 10.41 31.8 15.36 64.79 21.46 97.53 1.46 7.82 3.02 15.63 6.22 22.95 3.59 8.22 7.49 8.93 13.41 2.23 8.61-9.73 14.65-21.08 19.27-33.14 10.3-26.92 18.05-54.48 20.25-83.38.16-2.04.99-4.02 1.5-6.03Z"
      className="dental-icon-5"
    />
    <path
      d="M446.63 243.56c-11.87 22.5-25.85 28.12-51.26 19.72-55.48-18.35-47.27-14.83-97.05.57-19.75 6.11-34.25 2.45-44.11-10.32-12.25-15.85-12.71-37.49.04-56.62 7.07-10.61 15.83-20.11 23.83-30.11 12.97-16.2 25.9-32.43 38.93-48.58 6.47-8.02 14-14.49 24.72-16.03 13.96-2 25.44 2.75 34.07 13.54 20.42 25.52 40.7 51.12 61.62 76.25 12.34 14.82 16.41 32.55 9.22 51.58ZM289.09 121.5c-.14 27.03-19.16 38.68-39.5 26.78-18.2-10.65-27.99-27.35-28.1-48.5-.13-23.99 20.4-35.22 40.66-22.39 17.17 10.88 25.89 27.13 26.94 44.11ZM354.37 48.14c.45-15.87 5.22-30 17.42-40.77 13.98-12.33 31.01-8.84 39.41 7.8 9.09 18.01 4.42 45.57-10.23 60.44-15.91 16.14-36.07 11.8-43.99-9.46-2.17-5.81-2.76-11.85-2.61-18.01ZM472.94 105.99c-.99 15.14-9.91 30.85-26.65 41.65-8.88 5.73-18.98 7.6-28.71 2.01-9.88-5.69-12.63-15.7-12.04-26.12 1.08-19.11 9.69-34.49 25.74-45.26 9.17-6.16 19.28-8.6 29.41-3.01 9.91 5.46 12.6 15.16 12.25 30.74ZM340.51 48.87c-.14 18.78-7.4 31.41-18.41 34.8-11.36 3.5-24.2-2.15-33.57-15.41-10.85-15.37-14.48-32.35-8.43-50.42 5.59-16.69 22.26-22.26 37.04-12.58 16.58 10.86 22.24 27.58 23.37 43.61Z"
      className="dental-icon-5"
    />
    <path
      d="M444.78 232.7c-6.73 25.43-21.21 33.99-46.29 26.89-10.85-3.07-21.56-6.68-32.18-10.48-13.28-4.75-26.27-4.02-39.4.55-10.36 3.61-20.8 7.03-31.34 10.06-12.52 3.6-24.92 4.5-34.83-6.24-10.36-11.22-12.97-24.57-9.32-39.18 1.72-6.87 4.72-13.33 9.23-18.78 9.12-11.01 18.6-21.72 27.93-32.55 6.47-5.93 14.94-5.38 22.54-5.74 58.5-2.71 102.43 21.66 131.72 72.38.61 1.05 1.03 2.21 1.94 3.06Z"
      className="dental-icon-2"
    />
    <path
      d="M444.78 232.7c-3.21 2.09-3.04-.79-4.02-2.53-19.94-35.26-49.53-58.08-88.87-67.7-21.02-5.14-42.25-4.15-63.32.53 11.78-15.14 22.97-30.8 35.5-45.3 13.86-16.03 33.44-15.61 47.57.62 10.61 12.18 20.08 25.35 30.27 37.91 9.6 11.85 19.5 23.46 29.22 35.21 9.9 11.97 15.83 25.28 13.64 41.26Z"
      className="dental-icon-3"
    />
    <path
      d="M226.24 100.59c-.05-8.67 1.99-16.53 9.99-21.25 8.13-4.8 16.02-2.14 23.4 2.11 15.9 9.15 26.71 30.78 24.29 48.08-2.19 15.66-13.99 22.46-28.6 16.46-15.97-6.55-29.07-27.01-29.08-45.4Z"
      className="dental-icon-4"
    />
    <path
      d="M358.87 48.53c.7-13.8 4.45-26.49 14.7-36.37 12.56-12.11 27.78-8.78 34.53 7.28 7.06 16.82 1.55 41.73-11.99 54.2-12.59 11.59-27.15 8.51-33.84-7.24-2.41-5.67-3.71-11.62-3.4-17.88Z"
      className="dental-icon-2"
    />
    <path
      d="M410.19 123.77c.42-19.79 15.12-40.36 32.61-45.61 11.62-3.49 20.7 1.28 24.32 12.79 6.68 21.21-11.6 52.34-33.35 56.79-13.8 2.83-23.93-7.47-23.58-23.97Z"
      className="dental-icon-3"
    />
    <path
      d="M335.6 52.51c-.14 1.56-.06 4.68-.73 7.63-1.88 8.32-5.23 15.75-14.12 18.81-9.01 3.1-16.32-.75-22.36-6.75-13.46-13.38-19.22-29.63-15.1-48.42 4.25-19.36 20.97-24.38 35.86-11.2 9.99 8.84 16.43 23.91 16.44 39.94Z"
      className="dental-icon-4"
    />
  </svg>
)
export default DentalIcon;
