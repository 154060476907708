import React, { useState } from "react";
import "./MasterCategoryPageBanner.css";
import DentalIcon from "./DentalIcon";
import ProdenPlaqueOffTickMark from "./ProdenPlaqueOffTickMark";
import SlideshowPaginationBar from "./SlideshowPaginationBar";

const MasterCategoryPageBanner = ({
  backgroundImageUrl = "https://cdn.thelittlepetshoppe.com/dog-collection-header-image-expanded.png ",
}) => {
  const [isPaused, setIsPaused] = useState(false); // State for play/pause

  const togglePlayPause = () => {
    setIsPaused((prev) => !prev); // Toggle play/pause state
  };

  return (
    <div className="master-category-banner-wrapper">
      <div
        className="master-category-banner"
        style={{
          backgroundImage: `url(${backgroundImageUrl})`,
          objectPosition: "center right", // Align the background image
        }}
      >
        {/* Left Section: Product Image and Description */}
        <div className="master-category-banner-content">
          <img
            src="https://cdn.thelittlepetshoppe.com/proden-soft-chews.png" // Product image
            alt="ProDen PlaqueOff Soft Chews"
            className="master-category-banner-product-image"
          />
          <div className="master-category-banner-text">
            <img
              src="https://cdn.thelittlepetshoppe.com/proden-plaqueoff-logo.png"
              alt="ProDen PlaqueOff Logo"
              className="master-category-banner-title-logo"
            />
            <h2 className="master-category-banner-title">Soft Chews</h2>
            <ul className="master-category-banner-benefits">
              <li>
                <ProdenPlaqueOffTickMark className="proden-plaqueoff-tick-mark" />{" "}
                Reduce plaque
              </li>
              <li>
                <ProdenPlaqueOffTickMark className="proden-plaqueoff-tick-mark" />{" "}
                Prevent tartar
              </li>
              <li>
                <ProdenPlaqueOffTickMark className="proden-plaqueoff-tick-mark" />{" "}
                Fresh breath
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Yellow Bar */}
        <div className="master-category-banner-footer">
          <p className="master-category-banner-footer-text">
            <span className="master-category-banner-footer-icon">
              <DentalIcon className="master-category-dental-icon" />
            </span>
            Clinically proven effect
          </p>
        </div>
      </div>
      {/* Pagination Bar */}
      <SlideshowPaginationBar
        isPaused={isPaused}
        togglePlayPause={togglePlayPause}
        dotCount={4}
      />
    </div>
  );
};

export default MasterCategoryPageBanner;
