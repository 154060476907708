import React, { useState } from 'react';
import './CategoryPageCategorySelector.css';

const CategoryPageCategorySelector = ({
  title = "Categories",
  categories = [],
  showSearch = true,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const remainingCount = categories.length - 6;

  // Toggle between expanded and collapsed states
  const handleToggle = () => setIsExpanded(!isExpanded);

  return (
    <div className="category-page-selector">
      {/* Title */}
      <h2 className="category-page-selector-title">{title}</h2>

      {/* Optional Search Bar */}
      {showSearch && (
        <div className="category-page-selector-search-container">
          <input
            type="text"
            placeholder={`Find a ${title.toLowerCase()}`}
            className="category-page-selector-search-input"
          />
          <button className="category-page-selector-search-icon">🔍</button>
        </div>
      )}

      {/* Category List */}
      <ul className="category-page-selector-list">
        {(isExpanded ? categories : categories.slice(0, 6)).map((category, index) => (
          <li key={index} className="category-page-selector-item">
            <input
              type="checkbox"
              id={`category-page-selector-item-${index}`}
            />
            <label htmlFor={`category-page-selector-item-${index}`}>
              {category}
            </label>
          </li>
        ))}
      </ul>

      {/* Toggle Buttons */}
      {remainingCount > 0 && (
        <button
          className="category-page-selector-toggle-button"
          onClick={handleToggle}
        >
          {isExpanded ? "Show less" : `+ ${remainingCount} more`}
        </button>
      )}
    </div>
  );
};

export default CategoryPageCategorySelector;
