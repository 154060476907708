import React from "react";
import "./PaginationBar.css";

const PaginationBar = ({
  currentPage = 1,
  resultsPerPage = 20,
  totalResults = 100,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalResults / resultsPerPage); // Calculate total pages
  const maxPageButtons = 5; // Maximum number of visible page buttons

  // Generate page numbers based on current page
  const getVisiblePages = () => {
    const pages = [];

    if (totalPages <= maxPageButtons) {
      // Show all pages if total pages <= maxPageButtons
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Handle ellipsis and dynamically visible pages
      if (currentPage <= 3) {
        pages.push(1, 2, 3, 4, "...", totalPages);
      } else if (currentPage >= totalPages - 2) {
        pages.push(1, "...", totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      } else {
        pages.push(
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages
        );
      }
    }

    return pages;
  };

  const visiblePages = getVisiblePages();

  return (
    <div className="pagination-bar">
      {/* Previous Button */}
      <button
        className={`pagination-button previous-button ${
          currentPage === 1 ? "disabled" : ""
        }`}
        onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        &lt; Previous
      </button>

      {/* Page Numbers */}
      <div className="pagination-pages">
        {visiblePages.map((page, index) =>
          page === "..." ? (
            <span key={index} className="pagination-ellipsis">
              ...
            </span>
          ) : (
            <button
              key={index}
              className={`pagination-button page-number ${
                page === currentPage ? "active" : ""
              }`}
              onClick={() => onPageChange(page)}
            >
              {page}
            </button>
          )
        )}
      </div>

      {/* Next Button */}
      <button
        className={`pagination-button next-button ${
          currentPage === totalPages ? "disabled" : ""
        }`}
        onClick={() => currentPage < totalPages && onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next &gt;
      </button>
    </div>
  );
};

export default PaginationBar;
