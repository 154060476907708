import React, {useState} from "react";
import { useParams } from "react-router-dom"; // Import for dynamic category routing
import "./CategoryPage.css";
import CategoryPageCategorySection from "../components/CategoryPageCategorySection";
import CategoryPageCategoryHeader from "../components/CategoryPageCategoryHeader";
import BrandCategorySelector from "../components/BrandCategorySelector";
import PlaceholderCategorySelector from "../components/PlaceholderCategorySelector";
import CategoryPageResultCountAndSort from "../components/CategoryPageResultCountAndSort";
import CategoryPageProductCard from "../components/CategoryPageProductCard";
import CategoryPageRecommendedProducts from "../components/CategoryPageRecommendedProducts";
import CategoryPageBottomAd from "../components/CategoryPageBottomAd";
import PaginationBar from "../components/PaginationBar";

const CategoryPage = () => {
  const { category } = useParams(); // Retrieve the dynamic category parameter
  const handleReadMoreClick = () => {
    console.log("Read More button clicked!");
  };
  // Number of results per page (defined in <CategoryPageResultCountAndSort />)
  const resultsPerPage = 20; // Replace with dynamic value if needed
  const [currentPage, setCurrentPage] = useState(1);
  const totalResults = 5400;

  const handlePageChange = (page) => {
    console.log(`Navigating to page: ${page}`);
    setCurrentPage(page);
  };

  // Dummy product data for demonstration
  const products = Array.from({ length: resultsPerPage }, (_, index) => ({
    id: index + 1,
    name: `Product ${index + 1}`,
    brand: `Brand ${index + 1}`,
    rating: parseFloat((Math.random() * 2 + 3).toFixed(1)), // Random rating between 3.0 and 5.0
    reviews: Math.floor(Math.random() * 100), // Random number of reviews
    pickupInfo: "ready within 2 hours",
    deliveryInfo: "as soon as 9pm",
    shippingInfo: `arrives, Fri, Dec ${(index % 30) + 1}`,
    price: parseFloat((Math.random() * 50 + 10).toFixed(2)), // Ensure the price is a valid number
    image: "https://cdn.thelittlepetshoppe.com/thkdogfood.jpg"
  }));

  return (
    <div className="category-page">
      {/* Main Content Section */}
      <main className="category-page-main">
        {/* Left Section */}
        <section className="category-page-left-section">
          <CategoryPageCategorySection />
          <PlaceholderCategorySelector />
          <BrandCategorySelector />
          <PlaceholderCategorySelector />
          <PlaceholderCategorySelector />
          <PlaceholderCategorySelector />
          <PlaceholderCategorySelector />
          <PlaceholderCategorySelector />
          <PlaceholderCategorySelector />
          <PlaceholderCategorySelector />
          <PlaceholderCategorySelector />
        </section>

        {/* Right Section */}
        <section className="category-page-right-section">
          <CategoryPageCategoryHeader />
          <CategoryPageResultCountAndSort />

          {/* Product Grid */}
          <div className="category-page-product-grid">
            {products.map((product) => (
              <CategoryPageProductCard key={product.id} product={product} />
            ))}
          </div>
          {/* Pagination Component */}
          <PaginationBar
            currentPage={currentPage}
            resultsPerPage={resultsPerPage}
            totalResults={totalResults}
            onPageChange={handlePageChange}
          />
        </section>
      </main>

      {/* Full-Width Section */}
      <section className="category-page-full-width-section">
        <CategoryPageRecommendedProducts />
        <CategoryPageBottomAd
          message="Give the gift of restful sleep this holiday season"
          subMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          buttonText="READ MORE"
          onClick={handleReadMoreClick}
        />
      </section>
    </div>
  );
};

export default CategoryPage;
