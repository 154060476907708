import React from "react";
import SkoutsHonorBackgroundPattern from "./SkoutsHonorBackgroundPattern";
import "./SkoutsHonorAd.css";

const SkoutsHonorAd = () => {
  return (
    <div className="skouts-honor-container">
      {/* Red Top Background */}
      <div className="background-section red-background">
        {/* White SVG Pattern */}
        <SkoutsHonorBackgroundPattern className="background-pattern" />

        {/* Content Section */}
        <div className="content-wrapper">
          {/* Left Section: Cat and Dog Images */}
          <div className="animals-container">
            <img
              src="https://cdn.thelittlepetshoppe.com/skoutshonor-dog-and-cat.png"
              alt="Dog & Cat"
              className="dog-cat-image"
            />
          </div>

          {/* Middle Section: Text */}
          <div className="text-content">
            <h2 className="main-title">
              SKOUT’S HONOR PRODUCTS AVAILABLE AT LITTLE PET SHOPEE
            </h2>
            <p className="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
              ipsum suspendisse ultrices gravida.
            </p>
            <button className="skouts-honor-shop-now-button">Shop Now</button>
          </div>

          {/* Right Section: Product Images */}
          <div className="products-container">
            <img
              src="https://cdn.thelittlepetshoppe.com/skoutshonor-products.png"
              alt="Skout's Honor Products"
              className="products-image"
            />
          </div>
        </div>
      </div>

      {/* White Bottom Background */}
      <div className="background-section white-background">
        <p className="bottom-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan
          lacus vel facilisis.
        </p>
      </div>
    </div>
  );
};

export default SkoutsHonorAd;
