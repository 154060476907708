import * as React from "react"
const MasterCategoryPageAdShape = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 1305.59 1080"
    className={className}
  >
    <path
      d="M974.19 0v451.41S964.18 570.72 820 578.73H138.17S6.01 590.31 0 714.06V1080h1147.4V859.19s-20.02-124.65 158.19-137.11V0h-331.4Z"
      style={{
        fill: "#ea0200",
      }}
    />
  </svg>
)
export default MasterCategoryPageAdShape;
