import React from 'react';
import { useParams } from 'react-router-dom';
import './CategoryPageCategoryHeader.css';

const CategoryPageCategoryHeader = () => {
  const { parentCategory, category } = useParams(); // Retrieve URL parameters

  return (
    <div className="category-header">
      {/* Breadcrumb */}
      <div className="breadcrumb">
        <span>{parentCategory}</span> / <span>{category}</span>
      </div>

      {/* Main Heading Section */}
      <div className="hero-section">
        <div className="hero-text">
          <h1>NEW {parentCategory.toUpperCase()} {category.toUpperCase()}</h1>
          <p>Explore our exclusive collection of {category} in {parentCategory} products.</p>
        </div>
        <div className="hero-image">
          <img
            src="https://cdn.thelittlepetshoppe.com/dog-collection.png" // Replace with an actual image URL
            alt={`${category}`}
          />
        </div>
      </div>
    </div>
  );
};

export default CategoryPageCategoryHeader;
