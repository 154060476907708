import React, { useEffect, useRef, useState } from "react";
import "./BrandCollection.css";
import { FaChevronLeft, FaChevronRight, FaImage } from "react-icons/fa";

const BrandCollection = () => {
  const collectionItems = [
    { id: 1, name: "Brand A", image: "" },
    { id: 2, name: "Brand B", image: "" },
    { id: 3, name: "Brand C", image: "" },
    { id: 4, name: "Brand D", image: "" },
    { id: 5, name: "Brand E", image: "" },
    { id: 6, name: "Brand F", image: "" },
    { id: 7, name: "Brand G", image: "" },
  ];

  const containerRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isInView, setIsInView] = useState(false);

  const placeholderImage = <FaImage className="placeholder-icon" />;

  // Scroll animation
  useEffect(() => {
    let scrollInterval;

    const handleScrollAnimation = () => {
      if (isInView && !isHovered) {
        scrollInterval = setInterval(() => {
          if (containerRef.current) {
            containerRef.current.scrollBy({ left: 1, behavior: "smooth" });
          }
        }, 10); // Scrolls slowly
      } else {
        clearInterval(scrollInterval);
      }
    };

    handleScrollAnimation();

    return () => clearInterval(scrollInterval);
  }, [isInView, isHovered]);

  // Intersection Observer to detect when the scroll-container is in view
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsInView(entry.isIntersecting),
      { threshold: 0.1 }
    );

    if (scrollContainerRef.current) {
      observer.observe(scrollContainerRef.current);
    }

    return () => {
      if (scrollContainerRef.current) {
        observer.unobserve(scrollContainerRef.current);
      }
    };
  }, []);

  return (
    <div
      className="brand-collection-wrapper"
      ref={scrollContainerRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <h2 className="collection-title">Brands Our Customers Love</h2>
      <div className="scroll-container">
        <button className="scroll-button left" onClick={() => containerRef.current.scrollBy({ left: -300, behavior: "smooth" })}>
          <FaChevronLeft />
        </button>
        <div className="brand-collection" ref={containerRef}>
          {collectionItems.map((item) => (
            <div className="brand-collection-item" key={item.id}>
              <div className="brand-collection-image-container">
                {item.image ? (
                  <img src={item.image} alt={item.name} className="brand-collection-image" />
                ) : (
                  placeholderImage
                )}
              </div>
              <p className="brand-collection-name">{item.name}</p>
            </div>
          ))}
        </div>
        <button className="scroll-button right" onClick={() => containerRef.current.scrollBy({ left: 300, behavior: "smooth" })}>
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default BrandCollection;
