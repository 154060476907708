import React, { useState } from "react";
import "./CategoryPageProductCard.css";

const CategoryPageProductCard = ({
  product = {
    name: "Whole Food Clusters Chicken, 5-lb",
    brand: "The Honest Kitchen",
    rating: 4.7,
    reviews: 57,
    pickupInfo: "ready within 2 hours",
    deliveryInfo: "as soon as 9pm",
    shippingInfo: "arrives, Fri, Dec 20",
    price: 19.0,
    image: null, // Default image is null
  },
}) => {
  const [quantity, setQuantity] = useState(1);

  const increaseQuantity = () => setQuantity(quantity + 1);
  const decreaseQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  return (
    <div className="category-page-product-card">
      {/* Image Placeholder */}
      <div className="category-page-product-card-image">
        {product.image ? (
          <img
            src={product.image}
            alt={product.name}
            className="category-page-product-card-image-content"
          />
        ) : (
          <div className="category-page-product-card-image-placeholder" />
        )}
      </div>

      {/* Product Details */}
      <div className="category-page-product-card-details">
        <h2 className="category-page-product-card-name">{product.name}</h2>
        <p className="category-page-product-card-brand">{product.brand}</p>

        {/* Rating */}
        <div className="category-page-product-card-rating">
          <span className="rating-stars">⭐️⭐️⭐️⭐️⭐️</span>
          <span className="rating-score">{product.rating}</span>
          <span className="rating-reviews">({product.reviews})</span>
        </div>

        {/* Availability */}
        <div className="category-page-product-card-availability">
          <p>
            <span className="availability-label">Pickup</span> <span className="availability-info">{product.pickupInfo}</span>
          </p>
          <p>
            <span className="availability-label">Delivery</span> <span className="availability-info">{product.deliveryInfo}</span>
          </p>
          <p>
            <span className="availability-label">Shipping</span> <span className="availability-info">{product.shippingInfo}</span>
          </p>
        </div>

        {/* Price */}
        <div className="category-page-product-card-price">
          ${product.price.toFixed(2)}
        </div>

        {/* Quantity and Add to Cart */}
        <div className="category-page-product-card-actions">
          <div className="category-page-quantity-controls">
            <button
              onClick={decreaseQuantity}
              className="category-page-quantity-button"
            >
              -
            </button>
            <input
              type="text"
              className="category-page-quantity-value"
              value={quantity}
              onChange={(e) => {
                const value = e.target.value;
                if (/^[1-9]\d*$/.test(value) || value === "") {
                  setQuantity(value === "" ? "" : parseInt(value, 10));
                }
              }}
              onBlur={() => {
                if (quantity === "" || quantity < 1) {
                  setQuantity(1);
                }
              }}
            />
            <button
              onClick={increaseQuantity}
              className="category-page-quantity-button"
            >
              +
            </button>
          </div>
          <button className="category-page-product-card-add-to-cart-button">
            Add to cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default CategoryPageProductCard;
