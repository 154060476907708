import * as React from "react"
const ProdenPlaqueOffTickMark = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 19.56 19.56"
    className={className}
  >
    <circle
      cx={9.78}
      cy={9.78}
      r={9.78}
      style={{
        fill: "#eab72a",
      }}
    />
    <path
      d="m4.07 10.55 3.7 3.56 7.73-8.03"
      style={{
        fill: "none",
        stroke: "#fff",
        strokeLinecap: "round",
        strokeMiterlimit: 10,
      }}
    />
  </svg>
)
export default ProdenPlaqueOffTickMark;
