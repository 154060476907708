// import React from "react";
// import "./OutwardHoundProductFeature.css";
//
// const OutwardHoundProductFeature = () => {
//   return (
//     <div className="slow-feeder-ad">
//       {/* Left Circle */}
//       <div className="circle left-circle"></div>
//
//       {/* Content Section */}
//       <div className="content">
//         <h2 className="title">
//           <span className="highlight">2 - IN - 1</span> <br />
//           SLOW FEEDER & DOG PUZZELS
//         </h2>
//         <p className="subtitle">Outward Hound Nina Ottosson Lickin'</p>
//         <p className="description">
//           Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
//         </p>
//         <button className="shop-button">Shop Now</button>
//       </div>
//
//       {/* Right Circle */}
//       <div className="circle right-circle"></div>
//     </div>
//   );
// };
//
// export default OutwardHoundProductFeature;



import React from "react";
import "./OutwardHoundProductFeature.css";

const OutwardHoundProductFeature = () => {
  return (
    <div className="slow-feeder-ad-container">
      {/* Left and Right Shapes */}
      <div className="background-shape left-shape"></div>
      <div className="background-shape right-shape"></div>
      <img className="outwardhound-dog-and-puzzlefeeder"
           alt="Outward Hound Dog and Puzzle Feeder"
           src="https://cdn.thelittlepetshoppe.com/outwardhound-dog-and-puzzlefeeder.png"
      />
      <img className="outwardhound-dog"
           alt="Outward Hound Dog"
           src="https://cdn.thelittlepetshoppe.com/outwardhound-dog.png"
      />
      <img className="outwardhound-puzzlefeeder"
           alt="Outward Hound Puzzle Feeder"
           src="https://cdn.thelittlepetshoppe.com/outwardhound-puzzlefeeder.png"
      />
      {/* Content Section */}
      <div className="content-wrapper">
        <h2 className="title">
          LICKIN' LAYERS <br/> DOG PUZZLE & FEEDER IN ONE
        </h2>
        <h3 className="subtitle">Level 2 dog puzzle game from Nina Ottosson by Outward Hound</h3>
        <p className="description">
          With over 100 treat compartments, this dog puzzle toy will keep dogs busy and engaged. It even has enough space to be used as a slow feeder in order to lengthen mealtimes and encourage your dog to eat at a slower, healthier pace. Perfect for dry dog food, kibble, treats, wet food and raw food, it´s easy to smear it out by taking apart the three layers and filling each layer with your dog's favorite food.
        </p>
        <button className="shop-now-button">Shop Now</button>
      </div>
    </div>
  );
};

export default OutwardHoundProductFeature;
