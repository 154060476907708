import React from 'react';
import PetCollections from './PetCollections';

const dogCollectionItems = [
  { id: 1, name: 'Dry Food', image: '' },
  { id: 2, name: 'Wet Food', image: '' },
  { id: 3, name: 'Treats', image: '' },
  { id: 4, name: 'Toys', image: '' },
  { id: 5, name: 'Harnesses', image: '' },
  { id: 6, name: 'Collars', image: '' },
  { id: 7, name: 'Leashes', image: '' },
  { id: 8, name: 'Grooming', image: '' },
  { id: 9, name: 'Travel', image: '' },
  { id: 10, name: 'Training', image: '' },
];

const DogCollections = () => (
  <PetCollections
      title="Dog Collections"
      parentCategory="Dog"
      collectionItems={dogCollectionItems}
  />
);

export default DogCollections;

