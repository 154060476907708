// src/components/ProductCollection.js
import React from 'react';
import './NewArrivalsSection.css'; // Import CSS for styling
import { FaChevronLeft, FaChevronRight, FaImage } from 'react-icons/fa';
import NewArrivalBadge from "./NewArrivalBadge"; // Import scroll icons

const NewArrivalsSection = ({ title, products }) => {
  // Placeholder image URL (you can replace this with your own or use an icon)
  const placeholderImage = <FaImage className="placeholder-icon" />;

  return (
    <div className="new-arrivals-wrapper">
      <h2 className="new-arrivals-title">{title}</h2>
      <div className="scroll-button left">
        <FaChevronLeft />
      </div>
      <div className="new-arrivals-product-collection">
        {products.map((product) => (
          <div className="new-arrivals-product-card" key={product.id}>
            <NewArrivalBadge className="new-arrivals-badge" />
            {/* Visible Box for Image and Name */}
              <div className="new-arrivals-product-image">
                {product.image ? (
                  <img src={product.image} alt={product.name} />
                ) : (
                  placeholderImage // Show placeholder icon if no image is provided
                )}
              </div>
              <p className="new-arrivals-product-name">{product.name}</p>
            {/* Product Details Outside the Visible Box */}
            <div className="new-arrivals-product-details">
              <p className="new-arrivals-brand-name">{product.brand}</p>
              <p className="new-arrivals-product-description">
                Product Name may occupy two lines
              </p>
              <div className="new-arrivals-price-section">
                <span className="new-arrivals-price">${product.price}</span>
              </div>
              <div className="new-arrivals-hidden-controls">
                  <div className="new-arrivals-quantity-controls">
                    <button className="new-arrivals-decrement">-</button>
                    <input type="text" value="1" readOnly className="new-arrivals-quantity" />
                    <button className="new-arrivals-increment">+</button>
                  </div>
                  <button className="new-arrivals-add-to-cart-button">Add to Cart</button>
                  <button className="new-arrivals-buy-now-button">Buy Now</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="scroll-button right">
        <FaChevronRight />
      </div>
    </div>
  );
};

export default NewArrivalsSection;
