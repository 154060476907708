import React from "react";
import ResultCountAndSort from "./ResultCountAndSort";

const CategoryPageResultCountAndSort = () => {
  const handleSortChange = (option) => {
    console.log("Selected Sort Option:", option);
  };

  return (
    <div>
      <ResultCountAndSort
        pageNumber={1}
        resultsPerPage={20}
        totalResults={4000} // Replace with dynamic results if available
        sortOptions={["Newest", "Price: Low to High", "Price: High to Low", "Best Sellers"]}
        onSortChange={handleSortChange}
      />
    </div>
  );
};

export default CategoryPageResultCountAndSort;
