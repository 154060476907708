import React from "react";
import "./MasterCategoryPageHorizontalAd.css";
import MasterCategoryPageAdShape from "./MasterCategoryPageAdShape";
import MasterCategoryPageAdPawPrints from "./MasterCategoryPageAdPawPrints";

const MasterCategoryPageHorizontalAd = () => {
  return (
    <div className="premium-pet-food-ad-container">
      {/* SVG Background Shape */}
      <div className="premium-pet-food-ad-red-shape-container">
        <MasterCategoryPageAdShape className="premium-pet-food-ad-red-shape" />
      </div>

      {/* Paw Prints */}
      <MasterCategoryPageAdPawPrints className="premium-pet-food-ad-paw-prints" />

      {/* Content Section */}
      <div className="premium-pet-food-ad-content">
        <div className="premium-pet-food-ad-heading">
          <span className="inline-image">
            <img
              src="https://cdn.thelittlepetshoppe.com/master-category-page-ad-dog-only.png"
              alt="Dog Inline"
              className="inline-dog-image"
            />
          </span>
          PREMIUM FOOD
          <br />
          FOR YOUR&nbsp;&nbsp;&nbsp;PET
          <span className="inline-image">
            <img
              src="https://cdn.thelittlepetshoppe.com/master-category-page-ad-dog-food.png"
              alt="Dog Food Inline"
              className="inline-dog-food-image"
            />
          </span>
        </div>
        <p className="premium-pet-food-ad-description">
          Lorem ipsum dolor sit Lorem ipsum dolor sit <br />
          Lorem ipsum dolor sit Lorem ipsum dolor sit
        </p>
        <div className="premium-pet-food-ad-stats">
          <div className="premium-pet-food-ad-stats-number">2K</div>
          <div className="premium-pet-food-ad-stats-plus">+</div>
          <div className="premium-pet-food-ad-stats-label">
            SATISFIED CUSTOMER
          </div>
        </div>
      </div>

      {/* Dog Image on Right */}
      <div className="premium-pet-food-ad-images">
        <img
          src="https://cdn.thelittlepetshoppe.com/master-category-page-ad-dog-image.png"
          alt="Dog on Right"
          className="premium-pet-food-ad-dog-right"
        />
      </div>
    </div>
  );
};

export default MasterCategoryPageHorizontalAd;