import React from "react";
import "./MasterCategoryPageSideAd.css";

const MasterCategoryPageSideAd = () => {
  return (
    <div className="master-category-page-side-ad-container">
        <div className="master-category-page-side-ad-image-container">
            <img
              src="https://cdn.thelittlepetshoppe.com/master-category-page-side-ad-image.png"
              alt="Dog Inline"
              className="master-category-page-side-ad-image"
            />
        </div>
        <div className="master-category-page-side-ad-content">
            <div className="master-category-page-side-ad-title">
                HEMP CHEWS
            </div>
            <div className="master-category-page-side-ad-subtitle">
                HIP AND JOINT CARE
            </div>
            <div className="master-category-page-side-ad-description">
                Get rid of osteoarthritis
            </div>
            <div className="master-category-page-side-ad-subdescription">
                For Dogs & Cats
            </div>
            <button className="master-category-page-side-ad-button">
                Shop Now
            </button>
        </div>
    </div>
  );
};

export default MasterCategoryPageSideAd;