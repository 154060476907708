import React from "react";

const MasterCategoryPageAdPawPrints = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="none"
  >
    <circle cx="20" cy="20" r="5" fill="#f5c5a0" />
    <circle cx="30" cy="25" r="7" fill="#f5c5a0" />
    <circle cx="40" cy="20" r="6" fill="#f5c5a0" />
    <circle cx="25" cy="35" r="6" fill="#f5c5a0" />
  </svg>
);

export default MasterCategoryPageAdPawPrints;
