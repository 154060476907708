import React from "react";
import "./Carna4FeatureSection.css";
import Carna4ProductDisplay from "./Carna4ProductDisplay";

const Carna4FeatureSection = () => {
  return (
    <div className="carna4-card">
      {/* Header */}
      <div className="carna4-header">
        <img
          src="https://cdn.thelittlepetshoppe.com/Carna4-logo.png"
          alt="Canadian Flag"
          className="carna4-header-logo"
        />
        <h2 className="carna4-title">Easy-chew Lamb Formula Dog Food</h2>
      </div>

      {/* Main Section */}
      <div className="carna4-main">
        {/* Left Text Section */}
        <div className="carna4-left">
          <p className="carna4-description">
            We use only 100% true food and you can easily pronounce the name of
            every ingredient in Carna4 because every one is grown only on
            Canadian and American farms.
          </p>
          {/* Features */}
          <div className="carna4-features">
            <div className="carna4-feature-wrapper">
              <img
                src="https://cdn.thelittlepetshoppe.com/Carna4-topleft.png"
                alt="Placeholder Image"
                className="carna4-feature-top-left"
              />
              <img
                  src="https://cdn.thelittlepetshoppe.com/Carna4-bottomleft.png"
                  alt="Placeholder Image"
                  className="carna4-bottom-left"
                />
              <div className="carna4-feature">
                <img
                  src="https://cdn.thelittlepetshoppe.com/Eagle-Carna4.png"
                  alt="Placeholder Image"
                  className="carna4-feature-eagle"
                />
                Is made only from ingredients grown on Canadian & American farms
              </div>
            </div>
            <div className="carna4-feature-wrapper">
              <div className="carna4-feature">
                <img
                  src="https://cdn.thelittlepetshoppe.com/Alphabets-Carna4.png"
                  alt="Placeholder Image"
                  className="carna4-feature-alphabets"
                />
                Pure vitamins, minerals, probiotics & enzymes from whole foods -
                Not Synthetics.
              </div>
            </div>
            <div className="carna4-feature-wrapper">
              <img
                  src="https://cdn.thelittlepetshoppe.com/Carna4-topright.png"
                  alt="Placeholder Image"
                  className="carna4-top-right"
              />
              <div className="carna4-feature">
                <img
                  src="https://cdn.thelittlepetshoppe.com/Clipboard-Carna4.png"
                  alt="Placeholder Image"
                  className="carna4-feature-clipboard"
                />
                We use only 100% true food and you can easily pronounce the name of every ingredient.
              </div>
            </div>
          </div>
          <div className="carna4-purchase">
            <div className="carna4-purchase-text">
              Recipe:
            </div>
            <div className="carna4-recipe selected">
              Lamb
            </div>
            <div className="carna4-recipe">
              Chicken
            </div>
            <div className="carna4-recipe">
              Venison
            </div>
            <div className="carna4-recipe">
              Duck
            </div>
            <div className="carna4-recipe">
              Fish
            </div>
          </div>
          <div className="carna4-purchase">
            <div className="carna4-purchase-text">
              Size:
            </div>
            <div className="carna4-size-one">
              2.2-lb
            </div>
            <div className="carna4-size-two">
              5-lb
            </div>
            <div className="carna4-quantity-control">
              <span className="carna4-decrease">-</span>1<span className="carna4-increase">+</span>
            </div>
            <div className="carna4-add-to-cart">
              Add to Cart
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="carna4-right">
          <Carna4ProductDisplay />
        </div>
      </div>
    </div>
  );
};

export default Carna4FeatureSection;
