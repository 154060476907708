import React from "react";
import { FaImage } from "react-icons/fa";
import "./FeaturedBlogs.css";

const FeaturedBlogs = () => {
  const blogs = [
    {
      id: 1,
      title: "Lorem ipsum dolor sit amet, sit amet consectetur adipiscing elit",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sollicitudin tellus magna, id mattis tortor convallis id. Ut facilisis malesuada lorem, eu egestas sem vestibulum eget. Vivamus dapibus magna eget purus interdum, at molestie diam placerat. Sed ultricies magna at sagittis sollicitudin. Maecenas nec quam tortor. Pellentesque eget nunc lacinia mauris semper pharetra at vel urna. Maecenas pellentesque neque quis metus feugiat congue. Nunc pellentesque nisi eu tempor hendrerit. Curabitur vulputate orci quis lacus malesuada suscipit. Morbi eget leo eu sem egestas vestibulum vel ut diam. Quisque tincidunt nulla nec mi laoreet, eu rutrum ante egestas. Interdum et malesuada fames ac ante ipsum primis in faucibus. Proin nec eros id metus ornare viverra. Donec sed massa odio. Vivamus mattis tincidunt felis, id volutpat arcu mattis eu.",
      image: "https://cdn.thelittlepetshoppe.com/pet-blog.png",
    },
    {
      id: 2,
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sollicitudin tellus magna, id mattis tortor convallis id. Ut facilisis malesuada lorem, eu egestas sem vestibulum eget. Vivamus dapibus magna eget purus interdum, at molestie diam placerat. Sed ultricies magna at sagittis sollicitudin. Maecenas nec quam tortor. Pellentesque eget nunc lacinia mauris semper pharetra at vel urna. Maecenas pellentesque neque quis metus feugiat congue. Nunc pellentesque nisi eu tempor hendrerit. Curabitur vulputate orci quis lacus malesuada suscipit. Morbi eget leo eu sem egestas vestibulum vel ut diam. Quisque tincidunt nulla nec mi laoreet, eu rutrum ante egestas. Interdum et malesuada fames ac ante ipsum primis in faucibus. Proin nec eros id metus ornare viverra. Donec sed massa odio. Vivamus mattis tincidunt felis, id volutpat arcu mattis eu.",
      image: "https://cdn.thelittlepetshoppe.com/pet-blog.png",
    },
    {
      id: 3,
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sollicitudin tellus magna, id mattis tortor convallis id. Ut facilisis malesuada lorem, eu egestas sem vestibulum eget. Vivamus dapibus magna eget purus interdum, at molestie diam placerat. Sed ultricies magna at sagittis sollicitudin. Maecenas nec quam tortor. Pellentesque eget nunc lacinia mauris semper pharetra at vel urna. Maecenas pellentesque neque quis metus feugiat congue. Nunc pellentesque nisi eu tempor hendrerit. Curabitur vulputate orci quis lacus malesuada suscipit. Morbi eget leo eu sem egestas vestibulum vel ut diam. Quisque tincidunt nulla nec mi laoreet, eu rutrum ante egestas. Interdum et malesuada fames ac ante ipsum primis in faucibus. Proin nec eros id metus ornare viverra. Donec sed massa odio. Vivamus mattis tincidunt felis, id volutpat arcu mattis eu.",
      image: "https://cdn.thelittlepetshoppe.com/pet-blog.png",
    },
    {
      id: 4,
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sollicitudin tellus magna, id mattis tortor convallis id. Ut facilisis malesuada lorem, eu egestas sem vestibulum eget. Vivamus dapibus magna eget purus interdum, at molestie diam placerat. Sed ultricies magna at sagittis sollicitudin. Maecenas nec quam tortor. Pellentesque eget nunc lacinia mauris semper pharetra at vel urna. Maecenas pellentesque neque quis metus feugiat congue. Nunc pellentesque nisi eu tempor hendrerit. Curabitur vulputate orci quis lacus malesuada suscipit. Morbi eget leo eu sem egestas vestibulum vel ut diam. Quisque tincidunt nulla nec mi laoreet, eu rutrum ante egestas. Interdum et malesuada fames ac ante ipsum primis in faucibus. Proin nec eros id metus ornare viverra. Donec sed massa odio. Vivamus mattis tincidunt felis, id volutpat arcu mattis eu.",
      image: "https://cdn.thelittlepetshoppe.com/pet-blog.png",
    },
    {
      id: 5,
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sollicitudin tellus magna, id mattis tortor convallis id. Ut facilisis malesuada lorem, eu egestas sem vestibulum eget. Vivamus dapibus magna eget purus interdum, at molestie diam placerat. Sed ultricies magna at sagittis sollicitudin. Maecenas nec quam tortor. Pellentesque eget nunc lacinia mauris semper pharetra at vel urna. Maecenas pellentesque neque quis metus feugiat congue. Nunc pellentesque nisi eu tempor hendrerit. Curabitur vulputate orci quis lacus malesuada suscipit. Morbi eget leo eu sem egestas vestibulum vel ut diam. Quisque tincidunt nulla nec mi laoreet, eu rutrum ante egestas. Interdum et malesuada fames ac ante ipsum primis in faucibus. Proin nec eros id metus ornare viverra. Donec sed massa odio. Vivamus mattis tincidunt felis, id volutpat arcu mattis eu.",
      image: "https://cdn.thelittlepetshoppe.com/pet-blog.png",
    },
    {
      id: 6,
      title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sollicitudin tellus magna, id mattis tortor convallis id. Ut facilisis malesuada lorem, eu egestas sem vestibulum eget. Vivamus dapibus magna eget purus interdum, at molestie diam placerat. Sed ultricies magna at sagittis sollicitudin. Maecenas nec quam tortor. Pellentesque eget nunc lacinia mauris semper pharetra at vel urna. Maecenas pellentesque neque quis metus feugiat congue. Nunc pellentesque nisi eu tempor hendrerit. Curabitur vulputate orci quis lacus malesuada suscipit. Morbi eget leo eu sem egestas vestibulum vel ut diam. Quisque tincidunt nulla nec mi laoreet, eu rutrum ante egestas. Interdum et malesuada fames ac ante ipsum primis in faucibus. Proin nec eros id metus ornare viverra. Donec sed massa odio. Vivamus mattis tincidunt felis, id volutpat arcu mattis eu.",
      image: "https://cdn.thelittlepetshoppe.com/pet-blog.png",
    },
  ];

  const placeholderImage = <FaImage className="placeholder-icon" />;

  return (
      <div className="featured-blogs-wrapper">
        <h2 className="collection-title">FEATURED BLOGS</h2>
        <div className="featured-blogs-container">
        {/* Left Column - Large Blog */}
        <div className="blogs-column large">
          <div className="blog-card large">
            <div className="blog-image">
              {blogs[0].image ? (
                <img src={blogs[0].image} alt={blogs[0].title} />
              ) : (
                placeholderImage
              )}
            </div>
            <div className="blog-info">
              <h3 className="blog-title large-title">{blogs[0].title}</h3>
              <p className="blog-description">{blogs[0].description}</p>
            </div>
          </div>
        </div>

        {/* Center Column - Medium Blogs */}
        <div className="blogs-column medium">
          {blogs.slice(1, 3).map((blog) => (
            <div key={blog.id} className="blog-card medium">
              <div className="blog-image">
                {blog.image ? (
                  <img src={blog.image} alt={blog.title} />
                ) : (
                  placeholderImage
                )}
              </div>
              <div className="blog-info">
                <h3 className="blog-title">{blog.title}</h3>
                <p className="blog-description">{blog.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Right Column - Small Blogs */}
        <div className="blogs-column small">
          {blogs.slice(3, 6).map((blog) => (
            <div key={blog.id} className="blog-card small">
              <div className="blog-image">
                {blog.image ? (
                  <img src={blog.image} alt={blog.title} />
                ) : (
                  placeholderImage
                )}
              </div>
              <div className="blog-info">
                <h3 className="blog-title">{blog.title}</h3>
                <p className="blog-description">{blog.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>
  );
};

export default FeaturedBlogs;
