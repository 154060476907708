import React, { useState, useEffect, useRef } from "react";
import LetterEnvelopeIcon from "./LetterEnvelopeIcon";
import LetterLetterIcon from "./LetterLetterIcon";
import LetterIcon from "./LetterIcon";
import "./AnimatedLetterSequence.css";

const AnimatedLetterSequence = () => {
  const [step, setStep] = useState(0); // Track animation step
  const containerRef = useRef(null);

  // Function to reset the animation when the element comes into view
  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setStep(1); // Start animation when in view
        setTimeout(() => setStep(3), 2000); // Step 3 after 2 seconds
      }
    });
  };

  // Use IntersectionObserver to detect when the component is in view
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Trigger when 50% visible
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  // Reset animation on hover
  const handleHover = () => {
    setStep(1); // Replay animation on hover
    setTimeout(() => setStep(3), 2000); // Step 3 after 2 seconds
  };

  return (
    <div
      ref={containerRef}
      className="letter-sequence-container"
      onMouseEnter={handleHover} // Replay animation on hover
    >
      {/* Step 1: Envelope Icon */}
      <div className="icon-container" style={{ zIndex: 2 }}>
        <LetterEnvelopeIcon className="letter-envelope-step1-icon" />
      </div>

      {/* Step 2: Letter Sliding Out */}
      {step >= 1 && step < 3 && (
        <div className="letter-coming-out" style={{ zIndex: 1 }}>
          <LetterLetterIcon className="letter-letter-step2-icon" />
        </div>
      )}

      {/* Step 3: Final Letter Icon */}
      {step === 3 && (
        <div className="final-icon" style={{ zIndex: 1 }}>
          <LetterIcon className="letter-final-icon" />
        </div>
      )}
      <img className="newsletter-envelope-shadow" alt="Newsletter Envelope Shadow" src="https://cdn.thelittlepetshoppe.com/newsletter-envelope-shadow.png"></img>
    </div>
  );
};

export default AnimatedLetterSequence;
