// import React from 'react';
// import { useParams } from 'react-router-dom';
// import './MasterCategoryPage.css';
// import MasterCategoryPageBanner from "../components/MasterCategoryPageBanner";
// import MasterCategoryPageHeaderCategories from "../components/MasterCategoryPageHeaderCategories";
// import CategoryPageCategorySection from "../components/CategoryPageCategorySection";
// import PlaceholderCategorySelector from "../components/PlaceholderCategorySelector";
// import BrandCategorySelector from "../components/BrandCategorySelector";
// import CategoryPageCategoryHeader from "../components/CategoryPageCategoryHeader";
// import CategoryPageResultCountAndSort from "../components/CategoryPageResultCountAndSort";
// import CategoryPageProductCard from "../components/CategoryPageProductCard";
// import MasterCategoryPageHorizontalAd from "../components/MasterCategoryPageHorizontalAd";
// import MasterCategoryPageSideAd from "../components/MasterCategoryPageSideAd";
//
// const MasterCategoryPage = () => {
//   const { category } = useParams(); // Retrieve the category from the URL
//   // Number of results per page (defined in <CategoryPageResultCountAndSort />)
//   const resultsPerPage = 20; // Replace with dynamic value if needed
//
//   // Dummy product data for demonstration
//   const products = Array.from({ length: resultsPerPage }, (_, index) => ({
//     id: index + 1,
//     name: `Product ${index + 1}`,
//     brand: `Brand ${index + 1}`,
//     rating: parseFloat((Math.random() * 2 + 3).toFixed(1)), // Random rating between 3.0 and 5.0
//     reviews: Math.floor(Math.random() * 100), // Random number of reviews
//     pickupInfo: "ready within 2 hours",
//     deliveryInfo: "as soon as 9pm",
//     shippingInfo: `arrives, Fri, Dec ${(index % 30) + 1}`,
//     price: parseFloat((Math.random() * 50 + 10).toFixed(2)), // Ensure the price is a valid number
//     image: "https://cdn.thelittlepetshoppe.com/thkdogfood.jpg"
//   }));
//
//   return (
//     <div className="master-category-page">
//       <MasterCategoryPageBanner />
//       <MasterCategoryPageHeaderCategories />
//       <main className="master-category-page-main">
//           <div className="master-category-page-left-section">
//               <CategoryPageCategorySection />
//               <PlaceholderCategorySelector />
//               <BrandCategorySelector />
//               <PlaceholderCategorySelector />
//               <PlaceholderCategorySelector />
//               <PlaceholderCategorySelector />
//               <PlaceholderCategorySelector />
//               <MasterCategoryPageSideAd />
//           </div>
//           <div className="master-category-page-right-section">
//               <CategoryPageResultCountAndSort />
//               {/* Product Grid */}
//               <div className="category-page-product-grid">
//                 {products.map((product) => (
//                   <CategoryPageProductCard key={product.id} product={product} />
//                 ))}
//               </div>
//               <MasterCategoryPageHorizontalAd />
//           </div>
//       </main>
//     </div>
//   );
// };
//
// export default MasterCategoryPage;



import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import './MasterCategoryPage.css';
import MasterCategoryPageBanner from "../components/MasterCategoryPageBanner";
import MasterCategoryPageHeaderCategories from "../components/MasterCategoryPageHeaderCategories";
import CategoryPageCategorySection from "../components/CategoryPageCategorySection";
import PlaceholderCategorySelector from "../components/PlaceholderCategorySelector";
import BrandCategorySelector from "../components/BrandCategorySelector";
import CategoryPageResultCountAndSort from "../components/CategoryPageResultCountAndSort";
import CategoryPageProductCard from "../components/CategoryPageProductCard";
import MasterCategoryPageHorizontalAd from "../components/MasterCategoryPageHorizontalAd";
import MasterCategoryPageSideAd from "../components/MasterCategoryPageSideAd";
import PaginationBar from "../components/PaginationBar";
import CategoryPageRecommendedProducts from "../components/CategoryPageRecommendedProducts";
import CategoryPageBottomAd from "../components/CategoryPageBottomAd";

const MasterCategoryPage = () => {
  const { category } = useParams(); // Retrieve the category from the URL
  const handleReadMoreClick = () => {
    console.log("Read More button clicked!");
  };

  const resultsPerPage = 21; // Number of results per page
  const productsPerRow = 4; // Number of products per row
  const [currentPage, setCurrentPage] = useState(1);
  const totalResults = 5400;

  const handlePageChange = (page) => {
    console.log(`Navigating to page: ${page}`);
    setCurrentPage(page);
  };

  // Dummy product data for demonstration
  const products = Array.from({ length: resultsPerPage }, (_, index) => ({
    id: index + 1,
    name: `Product ${index + 1}`,
    brand: `Brand ${index + 1}`,
    rating: parseFloat((Math.random() * 2 + 3).toFixed(1)),
    reviews: Math.floor(Math.random() * 100),
    pickupInfo: "ready within 2 hours",
    deliveryInfo: "as soon as 9pm",
    shippingInfo: `arrives, Fri, Dec ${(index % 30) + 1}`,
    price: parseFloat((Math.random() * 50 + 10).toFixed(2)),
    image: "https://cdn.thelittlepetshoppe.com/thkdogfood.jpg"
  }));

  return (
    <div className="master-category-page">
      <MasterCategoryPageBanner />
      <MasterCategoryPageHeaderCategories />
      <main className="master-category-page-main">
        <div className="master-category-page-left-section">
          <CategoryPageCategorySection />
          <PlaceholderCategorySelector />
          <BrandCategorySelector />
          <PlaceholderCategorySelector />
          <PlaceholderCategorySelector />
          <PlaceholderCategorySelector />
          <PlaceholderCategorySelector />
          <MasterCategoryPageSideAd />
        </div>
        <div className="master-category-page-right-section">
          <CategoryPageResultCountAndSort />
          {/* Product Grid */}
          <div className="category-page-product-grid">
            {products.map((product, index) => {
              // Full-width Horizontal Ad in the second row
              if (index === productsPerRow) {
                return (
                  <React.Fragment key={`ad-horizontal-${index}`}>
                    <div className="category-page-product-grid-full-width">
                      <MasterCategoryPageHorizontalAd />
                    </div>
                  </React.Fragment>
                );
              }

              // Side Ad as the last card of the third row
              if (index === productsPerRow * 3) {
                return (
                  <React.Fragment key={`ad-side-${index}`}>
                    <MasterCategoryPageSideAd
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  </React.Fragment>
                );
              }

              // Regular Product Card
              return <CategoryPageProductCard key={product.id} product={product} />;
            })}
          </div>
          {/* Pagination Component */}
          <PaginationBar
            currentPage={currentPage}
            resultsPerPage={resultsPerPage}
            totalResults={totalResults}
            onPageChange={handlePageChange}
          />
        </div>
      </main>
      {/* Full-Width Section */}
      <section className="master-category-page-full-width-section">
        <CategoryPageRecommendedProducts />
        <CategoryPageBottomAd
          message="Give the gift of restful sleep this holiday season"
          subMessage="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          buttonText="READ MORE"
          onClick={handleReadMoreClick}
        />
      </section>
    </div>
  );
};

export default MasterCategoryPage;
