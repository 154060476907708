import * as React from "react"
const SkoutsHonorBackgroundPattern = ({className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 199.44 134.64"
    className={className}
  >
    <path
      d="M0 40.01c5.72-6.2 13.63-10.77 22.02-6.56s12.32 14.51 16.95 21.87c5.49 8.73 21.25 32.04 32.45 17.86 9.64-12.2-7.31-34.71-10.52-47.21-2.94-11.45.25-18.92 11.36-23.1 34.88-13.14 46.7 27.31 38.96 53.55-5.96 20.22-30.4 35.36-11.39 57.62 7.86 9.2 19.46 13.75 29.72 5.02 12-10.2 3.22-26.7-3.99-36.96-3.86-5.49-13.23-14.69-5.82-21.48 4.99-4.58 10.73-2.87 15.56.72 11.94 8.87 33.04 34.22 48.19 16.76 14.14-16.29-5.16-26.46-18.52-31.57-5.65-2.16-11.67-3.31-16.67-6.81-9.91-6.92-13.06-17.87-9.28-29.31.41-1.25 1.1-2.43 1.45-3.71l9.59 4.44c.46.41-.58 2.11-.79 2.8-5.35 17.6 11.92 19.16 23.55 24.03 15.8 6.63 32.68 19.39 24.26 38.84-5.02 11.59-16.49 19.46-29.38 17.78-13.56-1.77-24.31-12.37-34.43-20.57l5.23 8.07c14.39 20.65 8.51 49.63-19.23 52.32l-.38.22h-2.16l-6.28-1.03c-15.96-4.68-29.03-20.98-28.47-37.9.65-19.64 18.12-30.52 20.34-49.15 1.37-11.51-1.79-36.08-17.59-35.54-3.18.11-12.81 2.4-13.87 5.79-1.84 5.91 6.05 18.77 8.51 24.48 6.07 14.07 10.35 33.03-4.42 43.34C56 97.84 38.51 75.08 29.61 60.83 26.65 56.1 22.23 46.31 17.7 43.5c-3.91-2.42-6.6 1.24-9.41 3.42L0 40.49v-.48Z"
      style={{
        fill: "#fff",
        stroke: "#fff",
      }}
    />
  </svg>
)
export default SkoutsHonorBackgroundPattern
